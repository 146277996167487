type Props = {
  className: string
  cardNumber: string
  description: string
  progressNumber: string
  progressBar: string
  color: string
  img: string
}

const CardsWidget20 = ({
  className,
  cardNumber,
  description,
  progressNumber,
  progressBar,
  color,
  img,
}: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{cardNumber}</span>

        <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
      </div>
    </div>
  </div>
)
export {CardsWidget20}
