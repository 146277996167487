import {useLazyQuery, useMutation} from '@apollo/client'
import {DELETE_MANY_PRIORITY} from '../mutation/priority.mutation'
import {GET_PRIORITIES_ANALYTICS} from '../queries/priority.query'

const usePriority = () => {
  //Mutation Call
  const [deleteManyPrioritiesById] = useMutation(DELETE_MANY_PRIORITY)

  // Query Calls
  const [prioritiesAnalytics] = useLazyQuery(GET_PRIORITIES_ANALYTICS)

  const deleteManyPriorities = async (ids: string[]) => {
    try {
      const request = {
        variables: {
          ids,
        },
      }
      const {data, errors} = await deleteManyPrioritiesById(request)

      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const getPrioritiesAnalytics = async () => {
    try {
      const {data, error} = await prioritiesAnalytics()
      return {
        data,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    deleteManyPriorities,
    getPrioritiesAnalytics,
  }
}

export default usePriority
