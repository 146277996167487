import Compressor from 'compressorjs'
import {FFmpeg} from '@ffmpeg/ffmpeg'
import {fetchFile, toBlobURL} from '@ffmpeg/util'
import {useRef} from 'react'

const useCompress = () => {
  const ffmpegRef = useRef(new FFmpeg())
  const compressImages = async (image: any): Promise<Blob | undefined> => {
    try {
      const compressedResult = await new Promise<Blob>((resolve, reject) => {
        new Compressor(image, {
          quality: 0.2,
          success: (result: Blob) => resolve(result),
          error: (error: Error) => reject(error),
        })
      })
      return compressedResult
    } catch (error) {
      console.error('Image compression failed:', error)
    }
  }

  const compressMedia = async (file: File, signal?: any) => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd'
    try {
      const ffmpeg = ffmpegRef.current

      if (signal?.aborted) {
        throw new DOMException('The user aborted a request.', 'AbortError')
      }

      await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      })

      await ffmpeg.writeFile(file.name, await fetchFile(file))

      await ffmpeg.exec([
        '-i',
        file.name,
        '-preset',
        'ultrafast',
        '-crf',
        '28',
        `output${file.name}`,
      ])

      // Continuously check for the abort signal in your potentially long-running operations
      if (signal?.aborted) {
        throw new DOMException('The user aborted a request.', 'AbortError')
      }

      const compressedData = await ffmpeg.readFile(`output${file.name}`)

      const compressedBlob = new Blob([compressedData], {type: file.type})

      Object.assign(compressedBlob, {
        lastModified: file.lastModified,
        name: file.name,
        // Add any other desired properties here
      })

      return compressedBlob
    } catch (error) {
      throw error
    }
  }

  return {compressImages, compressMedia}
}

export default useCompress
