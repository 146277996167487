import {useFormik} from 'formik'
import {useState} from 'react'
import * as Yup from 'yup'
import uploadImageOnS3, {uploadDirectOnS3} from '../../helper/s3Helper'
import {useLazyQuery, useMutation} from '@apollo/client'
import {ADD_ADVERTISEMENT, IS_AD_PRIORITY_TAKEN} from '../../queries/advertisement.query'
import {validateImageFile} from '../../helper/imageHelpers'
import useCompress from '../../hooks/useCompress'
import {ADS_IMAGE_LOCATION} from '../../../constant'

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

interface LocationOption {
  readonly value: string
  readonly label: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

export const locationOptions: readonly LocationOption[] = [
  {value: 'home', label: 'Home Module', isFixed: true, isDisabled: false},
  {value: 'audio', label: 'Audio Module', isFixed: true, isDisabled: false},
  {value: 'video', label: 'Video Module', isFixed: true, isDisabled: false},
]

const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5 MB in bytes
const IMAGE_WIDTH = 889
const IMAGE_HEIGHT = 485

const advertisementDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  location: Yup.string().required('Location is required'),
  priority: Yup.string().required('Priority is required'),
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End date is required'),
  image: Yup.mixed().nullable().required('Image is required'),
  url: Yup.string().matches(
    URL,
    'Wrong url format, please enter url in correct format example (https://www.google.com/)'
  ),
})

const CreateNewAdd = () => {
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [imgHeight, setImgHeight] = useState(0)
  const [imageWidth, setImgWidth] = useState(0)

  const [imageError, setImageError] = useState('')
  const [locations, setLocations] = useState(locationOptions)
  const [isPriorityError, setIsPriorityError] = useState(false)

  const [IsAdPriorityTaken] = useLazyQuery(IS_AD_PRIORITY_TAKEN)
  const [createAdvertisement] = useMutation(ADD_ADVERTISEMENT)

  // Compressor
  const {compressImages} = useCompress()

  const handlePriorityCheck = async (priority: string, location: string) => {
    let isTaken
    if (priority) {
      const response = await IsAdPriorityTaken({
        variables: {
          priority: parseInt(priority),
          isEdit: false,
          location,
        },
      })
      isTaken = response?.data?.isAdPriorityTaken?.isTaken
      if (isTaken) {
        setError(true)
        setMessage(
          `Priority is taken in ${
            location.charAt(0).toUpperCase() + location.slice(1)
          } module please choose another.`
        )
        setIsPriorityError(isTaken)
        setShowModal(isTaken)
        formik.setFieldValue('priority', '')
      } else {
        formik.setFieldValue('priority', parseInt(priority))
      }
    }
    return isTaken
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      location: 'home',
      priority: '',
      startDate: '',
      endDate: '',
      image: null,
      url: '',
      height: imgHeight || 0,
      width: imageWidth || 0,
    },

    validationSchema: advertisementDetailsSchema,
    onSubmit: async (values) => {
      const isTaken = await handlePriorityCheck(values.priority, values.location)
      if (isTaken) {
        return
      }
      try {
        setLoading(true)
        const {image, name} = values
        //Compress Image

        // const compressedImage = await compressImages(image)

        let image_url = await uploadImageOnS3({
          file: image,
          title: name,
          type: ADS_IMAGE_LOCATION,
        })

        const request = {
          variables: {
            ...values,
            priority: parseInt(values.priority),
            image: image_url,
          },
        }

        const response = await createAdvertisement(request)

        setError(false)
        setMessage('Advertisement created successfully')
      } catch (error: any) {
        console.log(error, 'error ad')
        setError(true)
        setMessage(error.message)
      } finally {
        setLoading(false)
        setShowModal(true)
        //formik.resetForm()
      }
    },
  })

  const maxLengthCheck = (object: any) => {
    if (Number(object.target.value) > Number(object.target.max)) {
      object.target.value = object.target.max
    }
    if (Number(object.target.value) < -1) {
      object.target.value = object.target.min
    }
  }

  const checkImage = async (e: any) => {
    const isValid = await validateImageFile(e.target.files[0])

    if (!isValid) {
      formik.setFieldValue('image', null)
      setImageError('Image is not valid')
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Create Banner</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {formik.values.image && (
                <Preview
                  setImgHeight={setImgHeight}
                  setImageWidth={setImgWidth}
                  file={formik.values.image}
                />
              )}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Image Upload</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    accept='image/*'
                    //onInput={checkImage}
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event: any) => {
                      setImageError('')
                      formik.setFieldValue('image', event.target.files[0])
                    }}
                  />
                  {imageError && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{imageError}</div>
                    </div>
                  )}
                  {formik.touched.image && formik.errors.image && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.image}</div>
                    </div>
                  )}
                  <div className='mt-2 ml-1'>
                    {' '}
                    Note: Standard image resolution should be 889 x 485 and size should be less than
                    5 MB with jpeg format
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Url</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Url'
                    {...formik.getFieldProps('url')}
                  />
                  {formik.touched.url && formik.errors.url && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.url}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Location</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('location')}
                  >
                    {locations.map((location: any) => (
                      <option key={location.value} value={location.value}>
                        {location.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.location && formik.errors.location && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.location}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Start Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Start Date'
                    {...formik.getFieldProps('startDate')}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.startDate}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>End Date</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='End Time'
                    {...formik.getFieldProps('endDate')}
                  />
                  {formik.touched.endDate && formik.errors.endDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.endDate}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Priority</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Priority'
                    type='number'
                    min={1}
                    max={20}
                    maxLength={2}
                    onInput={maxLengthCheck}
                    {...formik.getFieldProps('priority')}
                  />
                  {formik.touched.priority && formik.errors.priority && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.priority}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <NavLink
           to='/apps/user-management/users'
           > */}
              <button type='submit' disabled={isLoading} className='btn btn-primary'>
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <button type='submit' className='btn btn-primary'>
                Submit
              </button> */}
              {/* </NavLink> */}
            </div>
          </form>
        </div>
        {showModal && (
          <MessageModal
            error={error}
            showModal={showModal}
            setShowModal={setShowModal}
            message={message}
            setError={setError}
            isPriorityError={isPriorityError}
            setIsPriorityError={setIsPriorityError}
          />
        )}
      </div>
    </>
  )
}
//@ts-ignore
const MessageModal = ({
  //@ts-ignore
  showModal,
  //@ts-ignore
  setShowModal,
  //@ts-ignore
  message,
  //@ts-ignore
  error,
  //@ts-ignore
  setError,
  //@ts-ignore
  isPriorityError,
  //@ts-ignore
  setIsPriorityError,
}) => {
  return (
    <>
      {' '}
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'>{error ? 'Error' : 'Success'}</h5>
                  {/* <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick = {()=> setShowModal(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div> */}
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {/* <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button> */}
                  <button
                    onClick={() => {
                      setShowModal(false)
                      if (!error) {
                        if (!isPriorityError) {
                          setIsPriorityError(false)
                          window.location.href = '/apps/ads-management/ads'
                        }
                      }
                      setIsPriorityError(false)
                      setError(false)
                    }}
                    type='button'
                    className='btn btn-primary'
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

//@ts-ignore
const Preview = ({file, setImgHeight, setImageWidth}) => {
  const [preview, setPreview] = useState(null)
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    //@ts-ignore
    setPreview(reader.result)
    var img = new Image()
    //@ts-ignore
    img.src = reader.result
    img.onload = () => {
      setImgHeight(img.height)
      setImageWidth(img.width)
    }
  }
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
        {preview ? (
          <img
            style={{justifySelf: 'center', height: '300px', width: '300px'}}
            src={preview}
            alt='preview'
          />
        ) : (
          'loading..'
        )}
      </div>
    </>
  )
}

export default CreateNewAdd
