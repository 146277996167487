import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import useFetchFaq from '../../hooks/useFetchFaq'
import {useLazyQuery, useMutation} from '@apollo/client'
import {CREATE_FAQ, DELETE_FAQ, IS_FAQ_PRIORITY_TAKEN, UPDATE_FAQ} from '../../queries/faq.query'
import {KTSVG} from '../../../_metronic/helpers'

const faqSchema = Yup.object().shape({
  question: Yup.string()
    .required('Question is required')
    .matches(/^\s*\S[\s\S]*$/, 'Question only contains blank characters'),
  answer: Yup.string()
    .required('Answer is required')
    .matches(/^\s*\S[\s\S]*$/, 'Answer only contains blank characters'),
  priority: Yup.string().required('Priority is required'),
})

const FAQ = () => {
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [updatedFAQ, setUpdatedFAQ] = useState()

  const [isDeleting, setDeleting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [deletedId, setDeletedId] = useState('')

  const {faq, refetchFaq, loading} = useFetchFaq()

  const [allFaqs, setAllFaqs] = useState([])
  const [createFaqR] = useMutation(CREATE_FAQ)
  const [IsFaqPriorityTaken] = useLazyQuery(IS_FAQ_PRIORITY_TAKEN)

  useEffect(() => {
    if (faq) {
      setAllFaqs(faq)
    }
  }, [faq])

  const handlePriorityCheck = async (priority) => {
    if (priority) {
      const response = await IsFaqPriorityTaken({
        variables: {
          priority: parseInt(priority),
          isEdit: false,
        },
      })

      const isTaken = response?.data?.isFaqPriorityTaken?.isTaken
      if (isTaken) {
        setMessage('Priority is taken please choose another.')
        //setIsPriorityError(isTaken)
        formik.setFieldValue('priority', '')
        setShowModal(isTaken)
      } else {
        formik.setFieldValue('priority', priority)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
      priority: '',
    },

    validationSchema: faqSchema,
    onSubmit: async (values) => {
      setLoading(true)

      const {question, answer, priority} = values
      const request = {
        variables: {
          question,
          answer,
          priority: parseInt(priority),
        },
      }
      try {
        const response = await createFaqR(request)

        setMessage('FAQ created successfully')
        setAllFaqs((pv) => {
          return [...pv, response.data.createFaqR.data]
        })
      } catch (error) {
        setError(true)
        setMessage(error.message)
      } finally {
        setLoading(false)
        setShowModal(true)
        formik.resetForm()
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Add FAQ</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Question</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Question'
                    {...formik.getFieldProps('question')}
                  />
                  {formik.touched.question && formik.errors.question && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.question}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Answer</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Answer'
                    {...formik.getFieldProps('answer')}
                  />
                  {formik.touched.answer && formik.errors.answer && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.answer}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Priority</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-sm fs-6 form-select-solid'
                    data-control='select2'
                    data-placeholder='Latest'
                    data-hide-search='true'
                    onInput={(e) => handlePriorityCheck(e.target.value)}
                    {...formik.getFieldProps('priority')}
                  >
                    <option value=''>{''}</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                    <option value='11'>11</option>
                    <option value='12'>12</option>
                    <option value='13'>13</option>
                    <option value='14'>14</option>
                    <option value='15'>15</option>
                    <option value='16'>16</option>
                    <option value='17'>17</option>
                    <option value='18'>18</option>
                    <option value='19'>19</option>
                    <option value='20'>20</option>
                    <option value='21'>21</option>
                    <option value='22'>22</option>
                    <option value='23'>23</option>
                    <option value='24'>24</option>
                    <option value='25'>25</option>
                  </select>
                  {formik.touched.priority && formik.errors.priority && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.priority}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <NavLink
         to='/apps/user-management/users'
         > */}
              <button type='submit' className='btn btn-primary'>
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* </NavLink> */}
            </div>
          </form>
        </div>
        {showModal && (
          <MessageModal
            error={error}
            showModal={showModal}
            setError={setError}
            setShowModal={setShowModal}
            message={message}
            updatedFAQ={updatedFAQ}
            setMessage={setMessage}
            isDeleting={isDeleting}
            setDeleting={setDeleting}
            deletedId={deletedId}
            setAllFaqs={setAllFaqs}
          />
        )}
      </div>
      {allFaqs
        ? allFaqs.map((item) => (
            <MyFAQ
              question={item.question}
              _id={item._id}
              key={item._id}
              answer={item.answer}
              priority={item.priority}
              showModal={showModal}
              setAllFaqs={setAllFaqs}
              setError={setError}
              setShowModal={setShowModal}
              message={message}
              updatedFAQ={updatedFAQ}
              setMessage={setMessage}
              isDeleting={isDeleting}
              setDeleting={setDeleting}
              deletedId={deletedId}
              setDeletedId={setDeletedId}
            />
          ))
        : 'Loading'}
    </>
  )
}

const MessageModal = ({
  showModal,
  setShowModal,
  setMessage,
  message,
  error,
  setError,
  updatedFAQ,
  isDeleting,
  setDeleting,
  deletedId,
  setAllFaqs,
}) => {
  const [deleteFaqR] = useMutation(DELETE_FAQ)
  const handleDelete = async () => {
    try {
      setDeleting(true)
      const request = {
        variables: {
          id: deletedId,
        },
      }
      const response = await deleteFaqR(request)

      setMessage('FAQ deleted successfully')
      setAllFaqs((pv) => {
        return pv.filter((item) => item._id !== deletedId)
      })
    } catch (error) {
      setError(true)
      setMessage(error.message)
    } finally {
      setDeleting(false)
    }
  }
  return (
    <>
      {' '}
      {isDeleting ? (
        <>
          <>
            <div
              className='modal fade show d-block'
              id='kt_modal_add_user'
              role='dialog'
              tabIndex={-1}
              aria-modal='true'
            >
              <div className='modal-dialog modal-dialog-centered mw-550px'>
                <div className='modal-content'>
                  <div className='modal-body scroll-y '>
                    <div className='modal-header'>
                      <h5 className='modal-title fs-2'> {'Delete'}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={() => setShowModal(false)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body fs-3'>
                      <p>{'Are you sure you want to delete this FAQ?'}</p>
                    </div>
                    <div className='modal-footer'>
                      <>
                        <button
                          type='button'
                          className='btn btn-light'
                          onClick={() => setShowModal(false)}
                          data-bs-dismiss='modal'
                        >
                          No
                        </button>
                        <button
                          onClick={handleDelete}
                          type='button'
                          disabled={!isDeleting}
                          className='btn btn-primary'
                        >
                          {!isDeleting ? 'Please wait...' : 'Yes'}
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-backdrop fade show'></div>
          </>
        </>
      ) : (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-800px'>
              <div className='modal-content'>
                <div className='modal-body scroll-y '>
                  <div className='modal-header'>
                    <h5 className='modal-title fs-2'>{error ? 'Error' : 'Success'}</h5>
                  </div>
                  <div className='modal-body fs-3'>
                    <div>{message}</div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      onClick={() => {
                        setShowModal(false)
                        setError(false)
                        setMessage()
                      }}
                      type='button'
                      className='btn btn-primary'
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

const MyFAQ = ({
  question,
  _id,
  answer,
  priority,
  showModal,
  setShowModal,
  setMessage,
  message,
  error,
  setError,
  setAllFaqs,
  isDeleting,
  setDeleting,
  deletedId,
  setDeletedId,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [updateFaqR] = useMutation(UPDATE_FAQ)
  const [IsFaqPriorityTaken] = useLazyQuery(IS_FAQ_PRIORITY_TAKEN)

  const handlePriorityCheck = async (priority) => {
    if (priority) {
      const response = await IsFaqPriorityTaken({
        variables: {
          priority: parseInt(priority),
          isEdit: true,
          faqId: _id,
        },
      })
      const isTaken = response?.data?.isFaqPriorityTaken?.isTaken
      if (isTaken) {
        setMessage('Priority is taken please choose another.')
        //setIsPriorityError(isTaken)
        setShowModal(isTaken)
      } else {
        formik.setFieldValue('priority', priority)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      id: _id,
      question: question,
      answer: answer,
      priority: priority,
    },

    validationSchema: faqSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const {answer, id, priority, question} = values
        const request = {
          variables: {
            answer,
            id,
            priority: parseInt(priority),
            question,
          },
        }
        const response = await updateFaqR(request)

        setMessage('FAQ updated successfully')
        setAllFaqs((pv) => {
          const faqs = pv.map((item) => {
            if (item._id === id) {
              return response.data.updateFaqR.data
            }
            return item
          })
          return faqs
        })
      } catch (error) {
        setError(true)
        setMessage(error.message)
      } finally {
        setLoading(false)
        setShowModal(true)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 curson-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target={`#faq-${_id}`}
        aria-expanded='true'
        aria-controls={`faq-${_id}`}
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{question}</h3>
        </div>
      </div>

      <div id={`faq-${_id}`} className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Question</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Question'
                  {...formik.getFieldProps('question')}
                />
                {formik.touched.question && formik.errors.question && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.question}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Answer</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  type='text'
                  className='form-control form-control-lg  h-125px form-control-solid'
                  placeholder='Answer'
                  {...formik.getFieldProps('answer')}
                />
                {formik.touched.answer && formik.errors.answer && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.answer}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Priority</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-sm fs-6 form-select-solid'
                  data-control='select2'
                  data-placeholder='Latest'
                  data-hide-search='true'
                  onInput={(e) => handlePriorityCheck(e.target.value)}
                  {...formik.getFieldProps('priority')}
                >
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                  <option value='23'>23</option>
                  <option value='24'>24</option>
                  <option value='25'>25</option>
                </select>
                {formik.touched.priority && formik.errors.priority && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.priority}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {/* <NavLink
         to='/apps/user-management/users'
         > */}
            <button type='submit' className='btn btn-primary'>
              {!isLoading && 'Update'}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              className='btn btn-primary mx-2'
              onClick={() => {
                setDeletedId(_id)
                setDeleting(true)
                setShowModal(true)
              }}
            >
              {!isDeleting && 'Delete'}
              {isDeleting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* <button
              type = "submit"
              className = "btn btn-primary"
              >
                update
              </button> */}
            {/* </NavLink> */}
          </div>
        </form>
      </div>
    </div>
  )
}

export default FAQ
