import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useUpdateContactusMutation, useUpdateTermsAndConditionsMutation} from '../../../redux/api'
import * as Yup from 'yup'
import {formatList} from '@formatjs/intl'
import {Navigate, useNavigate} from 'react-router-dom'
import {useMutation} from '@apollo/client'
import {UPDATE_CREATE_SETTING} from '../../queries/setting.query'
import useSetting from '../../hooks/useSetting'

const termsAndConditionsSchema = Yup.object().shape({
  androidVersion: Yup.string().required('Android version is required'),
  iosVersion: Yup.string().required('IOS version is required'),
})

const VersionControl = () => {
  const [error, setError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [updateSetting] = useMutation(UPDATE_CREATE_SETTING)

  const [setting, setSetting] = useState({})

  const {getVersion, updateVersion} = useSetting()

  useEffect(() => {
    const init = async () => {
      try {
        const {data, error, loading} = await getVersion()
        setSetting(data?.getAppVersion)
      } catch (error) {
        setError(true)
      }
    }
    init()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      androidVersion: setting?.androidVersion || '',
      iosVersion: setting?.iosVersion || '',
    },
    validationSchema: termsAndConditionsSchema,

    onSubmit: async (values) => {
      setLoading(true)
      try {
        const {data, errors} = await updateVersion(values.androidVersion, values.iosVersion)

        if (errors) {
          setError(true)
          setMessage('Something went wrong, please try again later.')
          return
        }
        setSetting(data?.updateAppVersion)
        setMessage('Versions control updated successfully')
      } catch (error) {
        setError(true)
        setMessage('Something went wrong, please try again later.')
      } finally {
        setLoading(false)
        setShowModal(true)
        // formik.resetForm()
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Version Control</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Android Version</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='40.2'
                    {...formik.getFieldProps('androidVersion')}
                  />
                  {formik.touched.androidVersion && formik.errors.androidVersion && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.androidVersion}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>IOS Version</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='3.1'
                    {...formik.getFieldProps('iosVersion')}
                  />
                  {formik.touched.iosVersion && formik.errors.iosVersion && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.iosVersion}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <NavLink
           to='/apps/user-management/users'
           > */}
              <button type='submit' className='btn btn-primary'>
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {showModal && (
                <MessageModal
                  setShowModal={setShowModal}
                  message={message}
                  error={error}
                  setError={setError}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {}
    </>
  )
}

//ts-ignore
const MessageModal = ({setShowModal, message, error, setError}) => {
  const navigate = useNavigate()
  return (
    <>
      {' '}
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'>{error ? 'Error' : 'Success'}</h5>
                  {/* <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick = {()=> setShowModal(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div> */}
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {/* <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button> */}
                  <button
                    onClick={() => {
                      setShowModal(false)
                      setError(false)
                    }}
                    type='button'
                    className='btn btn-primary'
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

export default VersionControl
