import {gql} from '@apollo/client'

export const ACTIVATE_DEACTIVATE_SUB_ADMIN = gql`
  mutation ActivateDeactivateSubAdmin($activateDeactivateSubAdminId: String!, $status: Boolean!) {
    activateDeactivateSubAdmin(id: $activateDeactivateSubAdminId, status: $status) {
      _id
      name
      email
      country
      access_token
      createdAt
    }
  }
`

export const GET_ADMIN_INFO = gql`
  query Admin {
    admin {
      _id
      name
      email
      subAdminType
      country
      role {
        isAdmin
        isSubAdmin
      }
      access {
        isTransaction
        isUser
        isPartner
        isSupport
        isSettings
        isReport
        isCategory
        isContent
        isAds
      }
      access_token
      createdAt
    }
  }
`
