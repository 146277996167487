import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../api'

type AuthState = {
  accessToken: string | null
  user: UserResponse | null
}

type UserResponse = {
  name: String
  email: String
  is_admin: boolean
  is_active: boolean
  role: String
  password: String
  phone_verfied: boolean
  accountCompleted: boolean
  followers: [number]
  following: [number]
  leagues: [number]
}

export const logout = createAsyncThunk('admin/logout', async (_, thunkAPI) => {
  localStorage.removeItem('accessToken')
  return null
})

const slice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
  } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null
        state.accessToken = null
        localStorage.removeItem('accessToken')
      })

      .addMatcher(api.endpoints.login.matchFulfilled, (state, {payload}) => {
        state.accessToken = payload.token
        localStorage.setItem('accessToken', payload.token)
        state.user = payload.user
      })
  },
})

export default slice.reducer
