import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import SubAdminDetails from '../SubAdmins/SubAdminDetails/SubAdminDetails'
import {UsersListWrapper} from './users-list/UsersList'
import UsersDetailsPage from './UsersDetailsPage'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/apps/all-users/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersManagement = () => {
  return (
    <Routes>
      {/* <Route element={<Outlet />}> */}
      <Route
        path='users'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
            <UsersListWrapper />
          </>
        }
      />
      <Route path='users/details-page' element={<UsersDetailsPage />} />

      {/* </Route> */}
      <Route element={<Navigate to='/apps/all-users/users' />} />
    </Routes>
  )
}

export default UsersManagement
