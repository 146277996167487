/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import useAdmin from '../../../../../app/hooks/useAdmin'

type Prop = {
  isAdmin: boolean
  isSubAdmin: boolean
  access: any
}
const SidebarMenuMain: React.FC<Prop> = ({isAdmin, isSubAdmin, access}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Actions</span>
        </div>
      </div>

      {/* New Sidebar Menu Items begin */}

      {/* Admin Profile Menu Item */}
      <SidebarMenuItem
        to='/crafted/account/overview'
        title='Admin Profile'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen003.svg'
      />

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isUser)) && (
        <>
          <SidebarMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Sub Admins'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='/apps/all-users/users'
            title='User management'
            icon='/media/icons/duotune/communication/com005.svg'
            fontIcon='bi-person'
          />
        </>
      )}

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isCategory)) && (
        <SidebarMenuItem
          to='/apps/category-management/categories'
          title='Category management'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-person'
        />
      )}

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isContent)) && (
        <>
          <SidebarMenuItem
            to='/apps/notification-management/notifications'
            title='Notification management'
            icon='/media/icons/duotune/communication/com003.svg'
            fontIcon='bi-person'
          />

          <SidebarMenuItemWithSub
            to=''
            title='Audio content management'
            icon='/media/icons/duotune/files/fil001.svg'
            fontIcon='bi-person'
          >
            <SidebarMenuItem
              to='/apps/audio-management/audios'
              title='Audio Albums'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/individual-audio/audios'
              title='Individual audio files'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to=''
            title='Video content management'
            icon='/media/icons/duotune/files/fil001.svg'
            fontIcon='bi-person'
          >
            <SidebarMenuItem
              to='/apps/video-management/videos'
              title='Video Albums'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/individual-video/videos'
              title='Individual video files'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to=''
            title='Content priority management'
            icon='/media/icons/duotune/files/fil001.svg'
            fontIcon='bi-person'
          >
            {/* <SidebarMenuItem
          to='/apps/priority-management/priorities'
          title='Top Trending priority'
          hasBullet={true}
        /> */}
            <SidebarMenuItem
              to='/apps/featured-series-management/series'
              title='Featured series priority'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/popular-series-management/series'
              title='Popular series priority'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/featured-videos-management/videos'
              title='Featured videos priority'
              hasBullet={true}
            />
            {/* <SidebarMenuItem
          to='/apps/popular-videos-management/videos'
          title='Popular videos priority'
          hasBullet={true}
        /> */}
            <SidebarMenuItem
              to='/apps/recommended-playlist-management/playlist'
              title='Latest Albums priority'
              hasBullet={true}
            />
            {/* <SidebarMenuItem
          to='/apps/latest-album-management/album'
          title='Latest album priority'
          hasBullet={true}
        /> */}
          </SidebarMenuItemWithSub>
        </>
      )}

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isAds)) && (
        <SidebarMenuItem
          to='/apps/ads-management/ads'
          title='Banners management'
          icon='/media/icons/duotune/communication/com010.svg'
          fontIcon='bi-person'
        />
      )}

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isSettings)) && (
        <SidebarMenuItemWithSub
          to='/settings'
          title='Settings'
          icon='/media/icons/duotune/files/fil001.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='settings/terms-and-condition'
            title='Terms and Condition'
            hasBullet={true}
          />
          <SidebarMenuItem to='settings/privacy-policy' title='Privacy Policy' hasBullet={true} />
          <SidebarMenuItem to='/settings/faq' title='FAQ' hasBullet={true} />
          <SidebarMenuItem to='/apps/all-feedbacks/feedbacks' title='Feedbacks' hasBullet={true} />
          <SidebarMenuItem
            to='/settings/version-control'
            title='Version Control'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {(isAdmin || access?.isSupport || (isSubAdmin && access?.isReports)) && (
        <SidebarMenuItem
          to='/apps/reports-management/reports'
          title='Reports'
          icon='/media/icons/duotune/files/fil003.svg'
          fontIcon='bi-person'
        />
      )}

      <SidebarMenuItem
        to='/contact'
        title='Contact Us'
        icon='/media/icons/duotune/communication/com010.svg'
        fontIcon='bi-person'
      />

      {/* New Sidebar Menu Items end */}

      {/* Old Sidebar Menu Items start  */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      

      */}
    </>
  )
}

export {SidebarMenuMain}
