/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useLoginMutation} from '../../../../redux/api'
import {gql, useMutation, useQuery} from '@apollo/client'
import {GET_SETTING} from '../../../queries/setting.query'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      message
      status
      access_token
      admin {
        _id
        name
        email
        subAdminType
        country
        access_token
        createdAt
      }
    }
  }
`

export function Login() {
  const [loginMutation, {data, loading: isLoading}] = useMutation(LOGIN_MUTATION)

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // const [login, {error, isLoading, data}] = useLoginMutation()

  const [typePassword, setTypePassword] = useState('password')

  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const {data, error} = await loginMutation({
          variables: {email: values.email, password: values.password},
        })

        console.log(data, 'here')
        if (data.login.status === '401') {
          setError(true)
          setStatus(data.login.message)
        } else {
          localStorage.setItem('accessToken', data.login.access_token)
          window.location.href = `/dashboard`
        }
      } catch (error) {
        formik.setErrors({password: 'Email or password is not correct'})
        setStatus('Email or password is not correct')
      } finally {
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10' style={{position: 'relative'}}>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />

        <i
          className={showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
          style={{position: 'absolute', top: '55%', right: '10%', zindex: 100}}
          onClick={() => setShowPassword((prevValue) => !prevValue)}
        ></i>
        {/* <div style={{position: 'relative'}}>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid'
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          <i
            className={showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
            onClick={() => setShowPassword((prevValue) => !prevValue)}
          ></i>
        </div> */}

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        {/* {error ? (
          <div className='fv-plugins-message-container '>
            <span role='alert'>{error?.data?.error}</span>
          </div>
        ) : (
          ''
        )} */}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!isLoading && <span className='indicator-label'>Continue</span>}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
