import {gql} from '@apollo/client'

export const DELETE_MANY_FEEDBACKS = gql`
  mutation DeleteFeedbacks($ids: [String]!) {
    deleteFeedbacks(ids: $ids) {
      status
      message
    }
  }
`
export const FETCH_PAGINATED_FEEDBACKS = gql`
  query GetUserFeedbacks(
    $page: Int!
    $pageSize: Int!
    $startDate: String
    $endDate: String
    $searchString: String
  ) {
    getUserFeedbacks(
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
    ) {
      feedbacks {
        _id
        name
        email
        description
        subscription
        createdAt
        updatedAt
      }
      total
      page
      limit
      totalPages
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
      status
      message
    }
  }
`
