import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../../../../_metronic/partials'
import {useLocation, useParams} from 'react-router'
import {
  useActivateOrDeactivateRewardMutation,
  useGetRewardByIdQuery,
} from '../../../../../../redux/api'
import clsx from 'clsx'

const RewardDetails = () => {
  return (
    <>
      <AdsDetailsViewHeader />
      <AdsDetailsViewOverview />
    </>
  )
}

const AdsDetailsViewHeader = () => {
  const {id} = useParams()

  const {data, error, isLoading} = useGetRewardByIdQuery(id)

  // const location = useLocation()
  // const fromDb = data?.is_active || false
  const [activate, setActivate] = useState(data?.status)
  const [activateOrDeactivate, response] = useActivateOrDeactivateRewardMutation()
  const [showModal, setShowModal] = useState(false)

  if (isLoading) {
    return <>Loading</>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <div className='d-flex justify-content-center align-items-center flex-column flex-wrap flex-sm-nowrap mb-3'>
          {/* Banner Image Container Begins */}
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {/* <img src={data?.photo} alt='image' /> */}
              {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}

              <div
                id='banner-image'
                style={{
                  // backgroundImage: `${data?.image && data.image}`,
                  // backgroundRepeat: 'no-repeat',
                  // backgroundSize: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              >
                <img
                  // src={toAbsoluteUrl(`/media/logos/AdsBannerImage2.jpg`)}
                  src={data?.image && data.image}
                  alt='Ad Banner Image'
                  width={'100%'}
                  style={{maxHeight: '485px'}}
                />
              </div>
              {/* <img src={toAbsoluteUrl(`/media/logos/FootballImageContest.jpg`)} alt='image' /> */}
              {/* 
              {data && data.photo ? (
                <div className='symbol-label'>
                  <img
                    src={toAbsoluteUrl(`/media/${user.avatar}`)}
                    alt={user.name}
                    className='w-100'
                  />
                  <img src={data?.photo} alt={'image'} className='w-100' />
                </div>
              ) : (
                data?.name && (
                  <div
                    className={clsx(
                      'symbol-label fs-5tx',
                      `bg-light-${data?.name[0] || 'a'}`,
                      `text-${data?.name[0] || 'a'}`
                    )}
                  >
                    {user.initials?.label}
                    {data?.name[0]}
                  </div>
                )
              )} */}
            </div>
          </div>

          <div className=''>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexSwitchDefault'
                onClick={() => setShowModal(true)}
                checked={data?.status}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              />
              <label
                htmlFor='flexSwitchDefault'
                style={{fontWeight: 'bold', margin: '4px 8px'}}
                className='btn'
              >
                {data?.status ? 'Enabled' : 'Disabled'}
              </label>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <MessageModal
          id={id}
          activate={activate}
          showModal={showModal}
          setShowModal={setShowModal}
          setActivate={setActivate}
        />
      )}
    </div>
  )
}

const MessageModal = ({showModal, setShowModal, message, activate, setActivate}) => {
  const {id} = useParams()
  const {data, error, isLoading} = useGetRewardByIdQuery(id)
  const [activateOrDeactivateAds] = useActivateOrDeactivateRewardMutation()

  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'> {data?.status ? 'Disable' : 'Enable'}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>
                    {data?.status
                      ? 'Are you sure you want to disable this reward ?'
                      : 'Are you sure you want to enable this reward ?'}
                  </p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => setShowModal(false)}
                    data-bs-dismiss='modal'
                  >
                    No
                  </button>
                  <button
                    onClick={async () => {
                      // @ts-ignore
                      await activateOrDeactivateAds(id)
                        .unwrap()
                        .then((user) => {
                          setActivate(user.status)
                          setShowModal(false)
                        })
                    }}
                    type='button'
                    className='btn btn-primary'
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

const AdsDetailsViewOverview = () => {
  const {id} = useParams()
  const {data, error, isLoading} = useGetRewardByIdQuery(id)
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Reward Details</h3>
          </div>

          {/* <Link
              to='#'
              to={`${id}/edit-ad`}
              className='btn btn-primary align-self-center'
            >
              Edit Ad
            </Link> */}
        </div>

        {/* Full Name */}
        <div className='card-body p-9'>
          {/* <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Image</label>
  
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.name ? data.name : '100x100'}</span>
              </div>
            </div> */}

          {/* Ad Name */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Level</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{data?.level ? data.level : 'Loading'}</span>
            </div>
          </div>

          {/* Contact */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Target points
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>
                {data?.requiredPoints ? data.requiredPoints : 'Loading'}
              </span>

              {/* <span className='badge badge-success'>Verified</span> */}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Reward</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{data?.reward ? data?.reward : 'Loading'}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>
    </>
  )
}

export default RewardDetails
