import {useLazyQuery, useMutation} from '@apollo/client'
import {ACTIVATE_DEACTIVATE_SUB_ADMIN} from '../mutation/admin.mutation'
import {GET_ADMIN_INFO} from '../queries/admin.query'

const useAdmin = () => {
  // Mutations calls
  const [activateDeactivateSubAdmin] = useMutation(ACTIVATE_DEACTIVATE_SUB_ADMIN)

  // Query calls
  const [admin] = useLazyQuery(GET_ADMIN_INFO)

  // Query Calls

  // Function calls

  const onActivateDeactivateSubAdmin = async (id: string, status: boolean) => {
    try {
      const request = {
        variables: {
          activateDeactivateSubAdminId: id,
          status,
        },
      }
      const {data, errors} = await activateDeactivateSubAdmin(request)
      return {data, errors}
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const adminInfo = async () => {
    try {
      const {data, error, loading} = await admin()

      return {
        loading,
        data,
        error,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {onActivateDeactivateSubAdmin, adminInfo}
}

export default useAdmin
