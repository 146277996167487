// @ts-nocheck
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

type SearchProps = {
  userSearch: String
  setUserSearch: Function
}

const UsersListHeader = ({
  genderFilter,
  setGenderFilter,
  userSearch,
  setUserSearch,
  setSDate,
  setEDate,
  sDate,
  eDate,
  page,
  setPage,
  subscription,
  setSubscription,
  ud,
}: SearchProps) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent userSearch={userSearch} setUserSearch={setUserSearch} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <UsersListGrouping />
        ) : (
          <UsersListToolbar
            ud={ud}
            sDate={sDate}
            setSDate={setSDate}
            eDate={eDate}
            setEDate={setEDate}
            page={page}
            setPage={setPage}
            genderFilter={genderFilter}
            setGenderFilter={setGenderFilter}
            subscription={subscription}
            setSubscription={setSubscription}
          />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
