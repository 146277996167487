import {gql} from '@apollo/client'

export const UPDATE_CREATE_SETTING = gql`
  mutation UpdateSetting(
    $privacyPolicy: String
    $termsAndConditions: String
    $contact: ContactInput
    $set: String
  ) {
    updateSetting(
      privacyPolicy: $privacyPolicy
      termsAndConditions: $termsAndConditions
      contact: $contact
      set: $set
    ) {
      message
      status
      data {
        privacyPolicy
        termsAndConditions
        contact {
          phoneNumber
          email
        }
      }
    }
  }
`

export const GET_SETTING = gql`
  query GetSettingR {
    getSettingR {
      message
      status
      data {
        privacyPolicy
        termsAndConditions
        contact {
          phoneNumber
          email
        }
      }
    }
  }
`

export const GET_APP_VERSION = gql`
  query GetAppVersion {
    getAppVersion {
      androidVersion
      iosVersion
    }
  }
`

export const UPDATE_APP_VERSION = gql`
  mutation ($androidVersion: String!, $iosVersion: String!) {
    updateAppVersion(androidVersion: $androidVersion, iosVersion: $iosVersion) {
      androidVersion
      iosVersion
    }
  }
`
