import {useMutation} from '@apollo/client'
import {FORGOT_ADMIN_PASSWORD, GET_EMAIL_OTP, VERIFY_EMAIL_OTP} from '../queries/admin.query'

const useAuth = () => {
  // Requests
  const [getEmailOTP] = useMutation(GET_EMAIL_OTP)

  const [verifyEmailOTP] = useMutation(VERIFY_EMAIL_OTP)

  const [forgotResetAdminPassword] = useMutation(FORGOT_ADMIN_PASSWORD)

  // Get OTP
  const getOtp = async (email: string) => {
    try {
      const request = {
        variables: {
          email,
        },
      }
      const {data, errors} = await getEmailOTP(request)
      return {data, errors}
    } catch (error: any) {
      return new Error(error.message)
    }
  }

  const verifyOtp = async (email: string, otp: string) => {
    try {
      const request = {
        variables: {
          email,
          otp,
        },
      }
      const {data, errors} = await verifyEmailOTP(request)
      return {data, errors}
    } catch (error: any) {
      console.log(error, 'err')
      return new Error(error.message)
    }
  }

  const forgotPassword = async (email: string, password: string) => {
    try {
      const request = {
        variables: {
          email,
          password,
        },
      }
      const {data, errors} = await forgotResetAdminPassword(request)

      return {data, errors}
    } catch (error: any) {
      return new Error(error.message)
    }
  }

  return {getOtp, verifyOtp, forgotPassword}
}

export default useAuth
