import {useEffect, useMemo, useState} from 'react'

const OtpForm = ({
  otpError,
  setOtpError,
  value,
  valueLength,
  onChange,
  handleOtpVerification,
  countdown,
  setCountdown,
  countdownReached,
  setCountdownReached,
  handleRequestOTP,
}) => {
  const RE_DIGIT = new RegExp(/^\d+$/)
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  // Count down for Otp Resend
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      setCountdownReached(true)
    }
  }, [countdown])

  const focusToNextInput = (target) => {
    setOtpError(false)

    const nextElementSibling = target.nextElementSibling

    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }
  const focusToPrevInput = (target) => {
    setOtpError(false)
    const previousElementSibling = target.previousElementSibling

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }
  const inputOnChange = (e, idx) => {
    setOtpError(false)
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '

    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue)

      if (!isTargetValueDigit) {
        return
      }

      focusToNextInput(target)
    } else if (targetValueLength === valueLength) {
      onChange(targetValue)

      target.blur()
    }
  }
  const inputOnKeyDown = (e) => {
    setOtpError(false)
    const {key} = e
    const target = e.target

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length)

    if (e.key !== 'Backspace' || targetValue !== '') {
      return
    }

    focusToPrevInput(target)
  }
  const inputOnFocus = (e) => {
    setOtpError(false)
    const {target} = e

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }

    target.setSelectionRange(0, target.value.length)
  }

  return (
    <>
      <form onSubmit={handleOtpVerification} style={{marginTop: '20px'}}>
        <div className='form-group'>
          <div
            className='otp-group'
            style={{
              display: 'flex',
              width: '100%',
              maxWidth: '360px',
              columnGap: '10px',
            }}
          >
            {valueItems.map((digit, idx) => {
              return (
                <input
                  key={idx}
                  type='text'
                  inputMode='numeric'
                  autoComplete='one-time-code'
                  pattern='\d{1}'
                  maxLength={valueLength}
                  className='otp-input'
                  value={digit}
                  style={{
                    padding: '0',
                    textAlign: 'center',
                    width: '100%',
                    height: '60px',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    lineHeight: 1,
                    borderRadius: '5px',
                    border: otpError ? '1px solid red' : '1px solid #ccc',
                  }}
                  onChange={(e) => inputOnChange(e, idx)}
                  onKeyDown={inputOnKeyDown}
                  onFocus={inputOnFocus}
                />
              )
            })}
          </div>
        </div>
        <p style={{textAlign: 'center', color: 'black'}}>
          {countdown === 0 ? 'You can request OTP' : `Resend OTP in ${countdown}s`}
        </p>

        <div className='form-group'>
          <button
            type='submit'
            className='btn btn-success w-100'
            style={{
              margin: '0 auto',
            }}
          >
            Verify <i className='icon-4'></i>
          </button>
        </div>
      </form>
      <p style={{textAlign: 'center'}}>Or</p>
      <div className='form-group'>
        <button
          className={`btn btn-info w-100 ${!countdownReached ? 'disabled' : ''}`}
          disabled={!countdownReached}
          style={{cursor: 'pointer', margin: '0 auto'}}
          onClick={handleRequestOTP}
        >
          {`Resend Otp  `}
        </button>
      </div>
    </>
  )
}

export default OtpForm
