import {gql} from '@apollo/client'

export const ADD_ADVERTISEMENT = gql`
  mutation CreateAdvertisement(
    $name: String!
    $location: String!
    $url: String
    $startDate: Date!
    $endDate: Date!
    $priority: Int!
    $image: String!
    $status: String
  ) {
    createAdvertisement(
      name: $name
      location: $location
      url: $url
      startDate: $startDate
      endDate: $endDate
      priority: $priority
      image: $image
      status: $status
    ) {
      message
      status
      data {
        name
        location
        url
        startDate
        endDate
        priority
        image
        status
      }
    }
  }
`

export const IS_AD_PRIORITY_TAKEN = gql`
  query IsAdPriorityTaken(
    $priority: Int
    $isEdit: Boolean
    $advertisementId: String
    $location: String
  ) {
    isAdPriorityTaken(
      priority: $priority
      isEdit: $isEdit
      advertisementId: $advertisementId
      location: $location
    ) {
      status
      isTaken
    }
  }
`

export const GET_PAGINATED_ADVERTISEMENTS = gql`
  query GetAdsPaginated(
    $page: Int
    $pageSize: Int
    $startDate: String
    $endDate: String
    $searchString: String
    $location: String
    $status: String
  ) {
    getAdsPaginated(
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
      location: $location
      status: $status
    ) {
      message
      status
      data {
        _id
        name
        location
        url
        startDate
        endDate
        priority
        image
        status
      }
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`

export const GET_AD_BY_ID = gql`
  query GetAdById($getAdByIdId: String!) {
    getAdById(id: $getAdByIdId) {
      message
      status
      data {
        _id
        name
        location
        url
        startDate
        endDate
        priority
        image
        status
      }
    }
  }
`

export const UPDATE_AD_STATUS = gql`
  mutation UpdateAdStatus($updateAdStatusId: String!, $status: String!) {
    updateAdStatus(id: $updateAdStatusId, status: $status) {
      message
      status
      data {
        _id
        name
        location
        url
        startDate
        endDate
        priority
        image
        status
      }
    }
  }
`

export const UPDATE_ADS = gql`
  mutation UpdateAdvertisementById(
    $advertisementId: String!
    $name: String
    $location: String
    $url: String
    $startDate: Date
    $endDate: Date
    $priority: Int
    $image: String
    $status: String
  ) {
    updateAdvertisementById(
      advertisementId: $advertisementId
      name: $name
      location: $location
      url: $url
      startDate: $startDate
      endDate: $endDate
      priority: $priority
      image: $image
      status: $status
    ) {
      message
      status
      data {
        _id
        name
        location
        url
        startDate
        endDate
        priority
        image
        status
      }
    }
  }
`

export const DELETE_AD_BY_ID = gql`
  mutation DeleteAdvertisement($deleteAdvertisementId: String!) {
    deleteAdvertisement(id: $deleteAdvertisementId) {
      message
      status
    }
  }
`
