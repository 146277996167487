// @ts-nocheck
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useGetUserReportFileQuery} from '../../../../../../redux/api'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import React, {useEffect, useState} from 'react'
import {URL} from '../../../../../../redux/api'
import {AllUsersData} from '../../table/UsersTable'
import * as XLSX from 'xlsx'
import useUsers from '../../../../../hooks/useUsers'
import {FeedbackListAction} from './FeedbackListAction'
import {useSelector} from 'react-redux'

const downloadExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, 'DataSheet.xlsx')
}

const UsersListToolbar = ({
  page,
  setPage,
  sDate,
  setSDate,
  setEDate,
  eDate,
  genderFilter,
  setGenderFilter,
  subscription,
  setSubscription,
  ud,
}) => {
  const {setItemIdForUpdate} = useListView()
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)

  const {usersData} = useUsers()

  const getData = async () => {
    try {
      setLoading(true)
      //const {data} = await usersData()

      downloadExcel(ud)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  const selectedFeedbacks = useSelector((state: any) => state.general.selectedFeedbacks)
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {selectedFeedbacks.length > 0 && <FeedbackListAction />}
      <UsersListFilter
        genderFilter={genderFilter}
        setGenderFilter={setGenderFilter}
        sDate={sDate}
        setSDate={setSDate}
        setEDate={setEDate}
        eDate={eDate}
        page={page}
        setPage={setPage}
        subscription={subscription}
        setSubscription={setSubscription}
      />

      {/* begin::Export */}
      <button
        type='button'
        className='btn btn-light-primary me-3'
        onClick={async () => {
          await getData()
        }}
        disabled={loading}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <a>{loading ? 'Exporting...' : 'Export'} </a>
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
