import {gql} from '@apollo/client'

export const FETCH_PAGINATED_MEDIAS = gql`
  query GetMediasPaginated(
    $page: Int
    $pageSize: Int
    $type: String
    $startDate: String
    $endDate: String
    $searchString: String
  ) {
    getMediasPaginated(
      page: $page
      pageSize: $pageSize
      type: $type
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
    ) {
      status
      data {
        _id
        title
        speaker
        url
        thumbnail_image_url
        nature
        albumID
        album {
          status
        }
        type
        isLiked
        createdAt
      }
      message
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
  }
`

export const ADD_MEDIA = gql`
  mutation AddMedia(
    $title: String
    $speaker: String
    $url: String
    $nature: String
    $description: String
    $thumbnailImageUrl: String
    $type: String
  ) {
    addMedia(
      title: $title
      speaker: $speaker
      url: $url
      nature: $nature
      description: $description
      thumbnail_image_url: $thumbnailImageUrl
      type: $type
    ) {
      status
    }
  }
`

export const GET_MEDIA_BY_ID = gql`
  query GetMediaById($id: String!) {
    getMediaById(id: $id) {
      data {
        _id
        title
        speaker
        url
        thumbnail_image_url
        nature
        albumID
        description
        type
        isLiked
        createdAt
      }
      status
    }
  }
`

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia(
    $updateMediaId: String
    $title: String
    $speaker: String
    $url: String
    $nature: String
    $description: String
    $thumbnailImageUrl: String
    $isLiked: Boolean
  ) {
    updateMedia(
      id: $updateMediaId
      title: $title
      speaker: $speaker
      url: $url
      nature: $nature
      description: $description
      thumbnail_image_url: $thumbnailImageUrl
      isLiked: $isLiked
    ) {
      status
    }
  }
`

export const GET_MEDIA_COUNT = gql`
  query GetMediaCount {
    getMediaCount {
      status
      message
      data {
        audioCount
        videoCount
      }
    }
  }
`

export const DELETE_MEDIA_BY_ID = gql`
  mutation DeleteMediaR($deleteMediaRId: String) {
    deleteMediaR(id: $deleteMediaRId) {
      message
      status
    }
  }
`
