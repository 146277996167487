import {gql} from '@apollo/client'

export const GET_ADMIN_INFO = gql`
  query Admin {
    admin {
      _id
      name
      email
      subAdminType
      country
      role {
        isAdmin
        isSubAdmin
      }
      access {
        isTransaction
        isUser
        isPartner
        isSupport
        isSettings
        isReport
        isCategory
        isContent
        isAds
      }
      access_token
      createdAt
    }
  }
`

export const GET_EMAIL_OTP = gql`
  mutation GetEmailOTP($email: String!) {
    getEmailOTP(email: $email) {
      message
      status
    }
  }
`

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOTP($email: String!, $otp: String!) {
    verifyEmailOTP(email: $email, otp: $otp) {
      message
      status
      id
    }
  }
`

export const FORGOT_ADMIN_PASSWORD = gql`
  mutation ForgotResetAdminPassword($email: String!, $password: String!) {
    forgotResetAdminPassword(email: $email, password: $password) {
      message
      status
    }
  }
`
