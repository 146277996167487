import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_APP_VERSION, GET_SETTING, UPDATE_APP_VERSION} from '../queries/setting.query'

const useSetting = () => {
  // Query Calls
  const [getSettingR] = useLazyQuery(GET_SETTING)
  const [getAppVersion] = useLazyQuery(GET_APP_VERSION)

  // Mutation Calls
  const [updateAppVersion] = useMutation(UPDATE_APP_VERSION)

  const getSetting = async () => {
    try {
      const {data, error, loading} = await getSettingR()
      return {
        setting: data?.getSettingR?.data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const getVersion = async () => {
    try {
      const {data, error, loading} = await getAppVersion()
      return {
        data,
        error,
        loading,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const updateVersion = async (androidVersion: string, iosVersion: string) => {
    try {
      const request = {
        variables: {
          androidVersion,
          iosVersion,
        },
      }

      const {data, errors} = await updateAppVersion(request)

      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    getSetting,
    updateVersion,
    getVersion,
  }
}

export default useSetting
