import React, {FC} from 'react'

type Props = {
  phone?: string
}

const UserPhoneNumberCell: FC<Props> = ({phone}) => {
  return <div className='badge badge-light fw-bolder'>{phone}</div>
}

export default UserPhoneNumberCell
