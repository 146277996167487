import {FC} from 'react'

type Props = {
  isBlocked?: boolean
}

const UserStatusCell: FC<Props> = ({isBlocked}) => (
  <>
    {' '}
    {!isBlocked ? (
      <div className='badge badge-light-success fw-bolder'>Active</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>Inactive</div>
    )}
  </>
)

export {UserStatusCell}
