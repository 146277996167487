export const isImageUrl = (url: string): boolean => {
  // Define a regular expression pattern to match common image file extensions and optional query parameters
  const pattern = /\.(jpeg|jpg|gif|png|svg)(\?.*)?$/i

  // Test the URL against the pattern and return true if it matches, false otherwise
  return pattern.test(url)
}

export function isFile(variable: any): variable is File {
  return variable instanceof File
}

export const validateImageFile = async (file: File): Promise<boolean> => {
  if (!file.type.startsWith('image/')) {
    return false
  }

  if (file.size > 5 * 1024 * 1024) {
    return false
  }

  const dataUrl = await new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.readAsDataURL(file)
  })

  const img = new Image()
  await new Promise<void>((resolve) => {
    img.onload = () => resolve()
    img.src = dataUrl
  })

  return img.width <= 889 && img.height <= 485
}
