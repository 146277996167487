// @ts-nocheck
import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import 'yup-phone'
import {
  useCreateAffiliateMutation,
  useGetAffiliateByIdQuery,
  useUpdateAffiliateDetailsMutation,
} from '../../../../../redux/api'

const profileDetailsSchema = Yup.object().shape({
  // fname: Yup.string().required('First name is required'),
  // lname: Yup.string().required('Last name is required'),
  phone: Yup.string().phone(true, 'invalid number').required('Contact phone is required'),
  email: Yup.string().required('Email is required').email('not a valid email address'),
  website_url: Yup.string().required('Website is required'),
  code: Yup.string().required('Affiliate Code is required'),
  country: Yup.string().required('Affiliate Country is required'),
  address: Yup.string().required('Affiliate Address is required'),
})

const EditAffiliate = () => {
  const {id} = useParams()
  const {data} = useGetAffiliateByIdQuery(id)

  let a = {}

  Object.assign(a, data)

  if (data) {
    Object.defineProperties(a, {
      fname: {value: data?.name.split(' ')[0], writable: true},
    })
    if (data.name.split(' ').length >= 2) {
      let str = data?.name
      const finalLName = str.slice(str.indexOf(' ') + 1)
      Object.defineProperties(a, {
        lname: {value: finalLName, writable: true},
      })
    }
  }

  const [updateAffiliate, {isLoading}] = useUpdateAffiliateDetailsMutation()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const formik = useFormik({
    initialValues: {
      fname: a?.fname,
      lname: a?.lname,
      email: a?.email,
      phone: a?.phone,
      website_url: a?.website_url,
      code: a?.code,
      country: a?.country,
      _id: a?._id,
      address: a?.address,
    },
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      await updateAffiliate(values)
        .unwrap()
        .then((res) => {
          setShowModal(true)
          setMessage(res.success)
          formik.resetForm()
        })
        .catch((err) => {
          console.table('failiure', err)
          setShowModal(true)
          setMessage(err.data.error)
          setError(true)
        })
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Edit Affiliate</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* Full Name Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        {...formik.getFieldProps('fname')}
                      />
                      {formik.touched.fname && formik.errors.fname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.fname}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        {...formik.getFieldProps('lname')}
                      />
                      {formik.touched.lname && formik.errors.lname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.lname}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Full Name Field Ends  */}

              {/* Contact Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Contact Phone</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone number'
                    {...formik.getFieldProps('phone')}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Contact Field Ends  */}

              {/* Email Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Email</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Email Field Ends  */}

              {/* Website Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Website</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Website'
                    {...formik.getFieldProps('website_url')}
                  />
                  {formik.touched.website_url && formik.errors.website_url && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.website_url}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Website Field Ends  */}

              {/* Affiliate Code Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Affiliate Code</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Affiliate Code'
                    {...formik.getFieldProps('code')}
                  />
                  {formik.touched.affiliateCode && formik.errors.affiliateCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.affiliateCode}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Affiliate Code Field Ends  */}

              {/* Affiliate Country Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Affiliate Country</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Affiliate Country'
                    {...formik.getFieldProps('country')}
                  />
                  {formik.touched.affiliateCountry && formik.errors.affiliateCountry && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.affiliateCountry}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Affiliate Country Field Ends  */}

              {/* Affiliate Address Field Begins  */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Affiliate Address</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Affiliate Address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.affiliateAddress && formik.errors.affiliateAddress && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.affiliateAddress}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Affiliate Address Field Ends  */}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <NavLink
             to='/apps/user-management/users'
             > */}
              <button type='submit' className='btn btn-primary'>
                {!isLoading && 'Edit Affilate'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* </NavLink> */}
            </div>
          </form>
        </div>
        {showModal && (
          <MessageModal
            error={error}
            showModal={showModal}
            setShowModal={setShowModal}
            message={message}
            setError={setError}
          />
        )}
      </div>
    </>
  )
}

const MessageModal = ({showModal, setShowModal, message, error, setError}) => {
  const navigate = useNavigate()
  return (
    <>
      {' '}
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'>{error ? 'Error' : 'Success'}</h5>
                  {/* <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick = {()=> setShowModal(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div> */}
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {/* <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button> */}
                  <button
                    onClick={() => {
                      setShowModal(false)
                      // navigate("apps/user-management/users")
                      setError(false)

                      // navigate(-1);
                    }}
                    type='button'
                    className='btn btn-primary'
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

export default EditAffiliate
