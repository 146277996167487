import {FC} from 'react'

type Props = {
  two_steps?: string
}

const UserTwoStepsCell: FC<Props> = ({two_steps}) => (
  <>
    {' '}
    {two_steps && two_steps !== 'free' ? (
      <div className='badge badge-light-success fw-bolder'>{two_steps?.toUpperCase()}</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>{'Free'}</div>
    )}
  </>
)

export {UserTwoStepsCell}
