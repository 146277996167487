// S3 Bucket Location
// Images
export const ADS_IMAGE_LOCATION = 'image/advertisement'
export const CATEGORY_IMAGE_LOCATION = 'image/category'
export const ALBUM_IMAGE_LOCATION = 'image/album'
export const AUDIO_IMAGE_LOCATION = 'image/audio'
export const VIDEO_IMAGE_LOCATION = 'image/video'

// MEDIA
export const AUDIO_MEDIA_LOCATION = 'audio'
export const VIDEO_MEDIA_LOCATION = 'video'

// PRIORITY_NUMBERS
export const MAX_CONTENT_PRIORITY_ORDER = 50
