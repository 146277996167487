import {FC, PropsWithChildren, useEffect, useState} from 'react'
import {HeaderProps} from 'react-table'
import {useListView} from '../../core/ListViewProvider'
import {User} from '../../core/_models'
import {useDispatch} from 'react-redux'
import {
  setSelectedAudio,
  setSelectedAudioAlbums,
  setSelectedFeedbacks,
} from '../../../../../../redux/slices/general_slice'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>
}

const UserSelectionHeader: FC<Props> = ({tableProps}) => {
  const [isSelected, setIsSelected] = useState(false)

  const dispatch = useDispatch()

  const handleSelectAll = () => {
    if (isSelected) {
      setIsSelected(false)
      dispatch(setSelectedFeedbacks([]))
    } else {
      setIsSelected(true)
      const ids = tableProps.data.map((item: any) => item._id)
      dispatch(setSelectedFeedbacks(ids))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedFeedbacks([]))
    }
  }, [])
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isSelected}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isSelected}
          onChange={handleSelectAll}
        />
      </div>
    </th>
  )
}

export {UserSelectionHeader}
