// @ts-nocheck
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {RootState} from './store'

export interface LoginResponse {
  token: string
  success: string
  user: UserResponse | null
}

type UserResponse = {
  name: String
  email: String
  is_admin: boolean
  is_active: boolean
  role: String
  password: String
  phone_verfied: boolean
  accountCompleted: boolean
  followers: [number]
  following: [number]
  leagues: [number]
}

export interface LoginRequest {
  email: string
  password: string
}

export interface activateDeactivateRequest {
  user_id: string
}
export interface activateDeactivateResponse {
  user: Object
}

export interface updateSubadminDetailsResponse {
  success: string
  updatedSubAdminDetails: Object
}

export interface updateSubadminDetailsRequest {
  sub_admin_email: string
  updatedDetails: Object
}

// export const URL = 'https://52.71.103.55:8080/'

// export const URL = 'http://localhost:8080/'

// const LIVE_URL = 'http://54.89.215.88:8080/'

export const URL = 'https://lfs-backend.onrender.com/'

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: URL,
    prepareHeaders: (headers, {getState}) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      // const accessToken = (getState() as RootState).auth.accessToken
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
  }),

  tagTypes: [
    'Post',
    'User',
    'League',
    'Leagues',
    'Admin',
    'SubAdmin',
    'SubAdmins',
    'OtherUser',
    'OtherUsers',
    'AdsSlots',
    'FAQs',
    'Ad',
    'Ads',
    'Rewards',
    'Reward',
    'RewardRequests',
    'RewardRequest',
    'Affiliates',
    'Affiliate',
    'Contests',
    'Contest',
  ],

  endpoints: (builder) => ({
    //login mutation
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'admin/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    //admin profile by id
    getMyAdminProfileById: builder.query({
      query: () => ({
        url: `admin/get_admin_by_id`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    // get sub admins
    getSubAdmins: builder.query({
      query: () => ({
        url: 'admin/get_all_subadmins',
        method: 'GET',
      }),
      providesTags: ['SubAdmins'],
    }),
    // activate or deactivate subadmin || user
    activateOrDeactivateSubAdmin: builder.mutation<
      activateDeactivateResponse,
      activateDeactivateRequest
    >({
      query: (credentials) => ({
        url: 'admin/activate_or_deactivate_user',
        method: 'POST',
        body: credentials,
      }),

      invalidatesTags: ['SubAdmins', 'SubAdmin'],
    }),
    // getting all users
    getAllUsers: builder.query({
      query: () => ({
        url: '/admin/get_all_users',
        method: 'GET',
      }),
      providesTags: ['OtherUsers'],
    }),
    // get user by id
    getUserById: builder.query({
      query: (id) => ({
        url: `admin/get_user_by_id/${id}`,
        method: 'GET',
      }),
      providesTags: ['OtherUser'],
    }),
    // update subadmin details
    updateSubAdminDetails: builder.mutation<
      updateSubadminDetailsResponse,
      updateSubadminDetailsRequest
    >({
      query: (credentials) => ({
        url: 'admin/update_sub_admin_details',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User', 'Admin', 'SubAdmin', 'SubAdmins'],
    }),
    // update contact us
    updateContactus: builder.mutation({
      query: (details) => ({
        url: 'admin/contactus',
        method: 'POST',
        body: details,
      }),
    }),
    // new sub admin
    createSubAdmin: builder.mutation({
      query: (credentials) => ({
        url: 'admin/create_admin_user',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Admin', 'SubAdmins', 'SubAdmin'],
    }),
    // update password for sub admin
    updatePassword: builder.mutation({
      query: (credentials) => ({
        url: 'admin/update_subadmin_password',
        method: 'POST',
        body: credentials,
      }),
    }),
    // get sub admin by id
    getSubAdminById: builder.query({
      query: (id) => ({
        // /get_subadmin_by_id
        url: `admin/get_subadmin_by_id/${id}`,
        method: 'GET',
      }),
      providesTags: ['SubAdmin'],
    }),

    // get, download user reports file
    getUserReportFile: builder.query({
      query: () => ({
        url: 'admin/download_users_report',
        method: 'GET',
      }),
    }),

    createNewAdd: builder.mutation({
      query: (newAdd) => ({
        url: 'admin/upload_multipart',
        method: 'POST',
        body: newAdd,
      }),
      invalidatesTags: ['Ads'],
    }),

    updateAdd: builder.mutation({
      query: (updatedAdd) => ({
        url: 'admin/update_ad',
        method: 'POST',
        body: updatedAdd,
      }),
      invalidatesTags: ['Ad', 'Ads'],
    }),

    getLeaguesReportFile: builder.query({
      query: () => ({
        url: 'admin/download_leagues_report',
        method: 'GET',
      }),
    }),
    getAdsSlots: builder.query({
      query: () => ({
        url: 'admin/get_ads_slots',
        method: 'GET',
      }),
      providesTags: ['AdsSlots'],
    }),
    getAllFAQs: builder.query({
      query: () => ({
        url: 'admin/get_all_faqs',
        method: 'GET',
      }),
      providesTags: ['FAQs'],
    }),
    // add faq questions
    addFAQ: builder.mutation({
      query: (faq) => ({
        url: 'admin/add_faq',
        method: 'POST',
        body: faq,
      }),
      invalidatesTags: ['FAQs'],
    }),
    updateFAQ: builder.mutation({
      query: (faq) => ({
        url: 'admin/update_faq',
        method: 'POST',
        body: faq,
      }),
      invalidatesTags: ['FAQs'],
    }),
    deleteFAQ: builder.mutation({
      query: (id) => ({
        url: 'admin/delete_faq',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['FAQs'],
    }),

    getAllAds: builder.query({
      query: () => ({
        url: 'admin/get_all_ads',
        method: 'GET',
      }),
      providesTags: ['Ads'],
    }),

    getAddById: builder.query({
      query: (id) => ({
        url: `admin/get_add_by_id/${id}`,
        method: 'GET',
      }),
      providesTags: ['Ad'],
    }),
    //get Leagues
    getAllLeagues: builder.query({
      query: () => ({
        url: `admin/get_all_leagues`,
        method: 'GET',
      }),
    }),

    getLeagueById: builder.query({
      query: (id) => ({
        url: `admin/get_league/${id}`,
        method: 'GET',
      }),
    }),

    createNewRewards: builder.mutation({
      query: (reward) => ({
        url: 'admin/create_reward',
        method: 'POST',
        body: reward,
      }),
      invalidatesTags: ['Rewards'],
    }),
    getAllRewards: builder.query({
      query: () => ({
        url: 'admin/get_all_rewards',
        method: 'GET',
      }),
      providesTags: ['Rewards'],
    }),
    updateReward: builder.mutation({
      query: (newReward) => ({
        url: 'admin/update_reward',
        method: 'POST',
        body: newReward,
      }),
      invalidatesTags: ['Rewards', 'Reward'],
    }),

    getAllRewardRequests: builder.query({
      query: (id) => ({
        url: 'admin/get_all_reward_requests',
        method: 'GET',
      }),
      providesTags: ['RewardRequests', 'RewardRequest'],
    }),
    getRewardById: builder.query({
      query: (id) => ({
        url: `admin/get_reward/${id}`,
        method: 'GET',
      }),
      providesTags: ['Reward'],
    }),

    // updated reward request
    addNotesToRewardRequest: builder.mutation({
      query: (notes) => ({
        url: 'admin/update_reward_request',
        method: 'POST',
        body: notes,
      }),
      invalidatesTags: ['RewardRequests', 'RewardRequest'],
    }),

    updateRewardRequestStatus: builder.mutation({
      query: (body) => ({
        url: `admin/update_reward_request_status`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['RewardRequests', 'RewardRequest'],
    }),

    createAffiliate: builder.mutation({
      query: (body) => ({
        url: 'admin/create_affiliate',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Affiliates'],
    }),
    getAllAffiliates: builder.query({
      query: () => ({
        url: 'admin/get_all_affiliates',
        method: 'GET',
      }),
      providesTags: ['Affiliates'],
    }),

    // Get reward request details
    getRewardRequestById: builder.query({
      query: (id) => ({
        url: `admin/get_reward_request/${id}`,
        method: 'GET',
      }),
      providesTags: ['RewardRequest'],
    }),

    // Get Affiliate by ID
    getAffiliateById: builder.query({
      query: (id) => ({
        url: `admin/get_affiliate/${id}`,
        method: 'GET',
      }),
      providesTags: ['Affiliate'],
    }),

    //update affiliate details
    updateAffiliateDetails: builder.mutation({
      query: (affiliateDetails) => ({
        url: `admin/update_affiliate_details`,
        method: 'POST',
        body: affiliateDetails,
      }),
      invalidatesTags: ['Affiliate', 'Affiliates'],
    }),

    activateOrDeactivateAffiliate: builder.mutation({
      query: (id) => ({
        url: `admin/activate_or_deactivate_affiliate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Affiliate', 'Affiliates'],
    }),

    //get all refrees
    getAllRefrees: builder.query({
      query: () => ({
        url: 'admin/get_all_refferes',
        method: 'GET',
      }),
    }),

    getAllContests: builder.query({
      query: () => ({
        url: 'admin/get_all_contests',
        method: 'GET',
      }),
      providesTags: ['Contests'],
    }),

    getContestById: builder.query({
      query: (id) => ({
        url: `admin/get_contest_by_id/${id}`,
        method: 'GET',
      }),
      providesTags: ['Contest'],
    }),

    getUserContestById: builder.query({
      query: (id) => ({
        url: `admin/get_contest_by_user/${id}`,
        method: 'GET',
      }),
    }),

    getUserLeaguesById: builder.query({
      query: (id) => ({
        url: `admin/get_leagues_by_user/${id}`,
        method: 'GET',
      }),
    }),

    getAllUsersPagination: builder.query({
      query: ({page, userSearch, gender, sDate, eDate}) => ({
        url: `admin/get_all_users_pagination?page=${page}&searchString=${userSearch}&gender=${gender}&sDate=${sDate}&eDate=${eDate}`,
        method: 'GET',
      }),
      providesTags: ['OtherUsers'],
    }),

    getAllContestsPagination: builder.query({
      query: ({page, userSearch, sDate, eDate}) => ({
        url: `admin/get_all_contests_pagination?page=${page}&searchString=${userSearch}&sDate=${sDate}&eDate=${eDate}`,
        method: 'GET',
      }),
      providesTags: ['Contests'],
    }),

    getAllLeaguesPagination: builder.query({
      query: ({page, userSearch, sDate, eDate}) => ({
        url: `admin/get_all_leagues_pagination?page=${page}&searchString=${userSearch}&sDate=${sDate}&eDate=${eDate}`,
        method: 'GET',
      }),
      providesTags: ['Leagues'],
    }),

    activateOrDeactivateAds: builder.mutation({
      query: (id) => ({
        url: `admin/activate_or_deactivate_ads/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Ads', 'Ad'],
    }),
    activateOrDeactivateReward: builder.mutation({
      query: (id) => ({
        url: `admin/activate_or_deactivate_reward/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Rewards', 'Reward'],
    }),

    getAllAdsPagination: builder.query({
      query: ({page, adSearch, sDate, eDate, location, status}) => ({
        url: `admin/get_all_ads_pagination?page=${page}&searchString=${adSearch}&sDate=${sDate}&eDate=${eDate}&location=${location}&status=${status}`,
        method: 'GET',
      }),
      providesTags: ['Ads'],
    }),

    getAllRewardsPagination: builder.query({
      query: ({page, adSearch}) => ({
        url: `admin/get_all_rewards_pagination?page=${page}&searchString=${adSearch}`,
        method: 'GET',
      }),
      providesTags: ['Rewards'],
    }),

    getAllRewardsRequestsPagination: builder.query({
      query: ({page, userSearch, status}) => ({
        url: `admin/get_all_rewards_requests_pagination?page=${page}&searchString=${userSearch}&status=${status}`,
        method: 'GET',
      }),
      providesTags: ['RewardRequests', 'RewardRequest'],
    }),

    getAllSubAdminsPagination: builder.query({
      query: ({page, userSearch, sDate, eDate}) => ({
        url: `admin/get_all_sub_admins_pagination?page=${page}&searchString=${userSearch}&sDate=${sDate}&eDate=${eDate}`,
        method: 'GET',
      }),
      providesTags: ['SubAdmins'],
    }),

    getAllAffiliatesPagination: builder.query({
      query: ({page, adSearch}) => ({
        url: `admin/get_all_affiliates_pagination?page=${page}&searchString=${adSearch}`,
        method: 'GET',
      }),
      providesTags: ['Affiliates', 'Affiliate'],
    }),

    getAllRefreesPagination: builder.query({
      query: ({page, adSearch, affiliateCode, sDate, eDate}) => ({
        url: `admin/get_all_refferes_pagination?page=${page}&searchString=${adSearch}&affiliateCode=${affiliateCode}&sDate=${sDate}&eDate=${eDate}`,
        method: 'GET',
      }),
      // providesTags:[""]
    }),

    getAllAffliateCodes: builder.query({
      query: () => ({
        url: 'admin/get_all_affiliate_codes',
        method: 'GET',
      }),
    }),

    getAllUserRewards: builder.query({
      query: (id) => ({
        url: `admin/get_user_rewards/${id}`,
        method: 'GET',
      }),
    }),

    getAllLeagueContests: builder.query({
      query: (id) => ({
        url: `admin/get_league_contests/${id}`,
        method: 'GET',
      }),
    }),

    updateTermsAndConditions: builder.mutation({
      query: (body) => ({
        url: 'admin/update_terms_and_condition',
        method: 'POST',
        body: body,
      }),
    }),

    updatePrivacyPolicy: builder.mutation({
      query: (body) => ({
        url: 'admin/update_privacy_policy',
        method: 'POST',
        body: body,
      }),
    }),

    // Get Refrees by ID
    getRefreesById: builder.query({
      query: (id) => ({
        url: `refrees/get_refrees/${id}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useActivateOrDeactivateSubAdminMutation,
  useGetMyAdminProfileByIdQuery,
  useGetSubAdminsQuery,
  useGetAllUsersQuery,
  useGetSubAdminByIdQuery,
  useUpdateSubAdminDetailsMutation,
  useGetUserByIdQuery,
  useCreateSubAdminMutation,
  useUpdatePasswordMutation,
  useGetUserReportFileQuery,
  useUpdateContactusMutation,
  useAddFAQMutation,
  useCreateNewAddMutation,
  useGetLeaguesReportFileQuery,
  useGetAdsSlotsQuery,
  useUpdateFAQMutation,
  useDeleteFAQMutation,
  useGetAllAdsQuery,
  useGetAllFAQsQuery,
  useGetAddByIdQuery,
  useCreateNewRewardsMutation,
  useGetAllRewardsQuery,
  useUpdateAddMutation,
  useGetAllLeaguesQuery,
  useGetLeagueByIdQuery,
  useGetAllRewardRequestsQuery,
  useGetRewardByIdQuery,
  useUpdateRewardMutation,
  useGetRewardRequestByIdQuery,
  useAddNotesToRewardRequestMutation,
  useUpdateRewardRequestStatusMutation,
  useCreateAffiliateMutation,
  useGetAllAffiliatesQuery,
  useGetAffiliateByIdQuery,
  useGetRefreesByIdQuery,
  useUpdateAffiliateDetailsMutation,
  useGetAllRefreesQuery,
  useActivateOrDeactivateAffiliateMutation,
  useGetAllContestsQuery,
  useGetContestByIdQuery,
  useGetUserLeaguesByIdQuery,
  useGetUserContestByIdQuery,
  useGetAllUsersPaginationQuery,
  useActivateOrDeactivateAdsMutation,
  useGetAllContestsPaginationQuery,
  useGetAllLeaguesPaginationQuery,
  useActivateOrDeactivateRewardMutation,
  useGetAllAdsPaginationQuery,
  useGetAllRewardsPaginationQuery,
  useGetAllRewardsRequestsPaginationQuery,
  useGetAllSubAdminsPaginationQuery,
  useGetAllAffiliatesPaginationQuery,
  useGetAllRefreesPaginationQuery,
  useGetAllAffliateCodesQuery,
  useUpdateTermsAndConditionsMutation,
  useUpdatePrivacyPolicyMutation,
  useGetAllUserRewardsQuery,
  useGetAllLeagueContestsQuery,
} = api
