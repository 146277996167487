/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}

    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user && user?.profile_picture ? (
          <div className='symbol-label'>
            <img src={user?.profile_picture} alt={'image'} className='w-100' />
          </div>
        ) : (
          user?.first_name && (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${user?.first_name[0] || 'a'}`,
                `text-${user?.first_name[0] || 'a'}`
              )}
            >
              {user?.first_name[0]}
            </div>
          )
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {user?.first_name}
      </a>

      <span
        // style={{textOverflow: (user.email.length) > 15 ? 'ellipsis' : ''}}>{user.email}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '160px',
          whiteSpace: 'nowrap',
        }}
      >
        {user?.email}
      </span>
    </div>
  </div>
)

const formatName = (name) => {
  if (name.length > 30) {
    return name.slice(0, -5) + '...'
  } else {
    return name
  }
}

export {UserInfoCell}
