// @ts-nocheck
import {useListView} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {KTCard} from '../../../../_metronic/helpers'
import {useState} from 'react'

const UsersList = () => {
  const [userSearch, setUserSearch] = useState('')
  const [genderFilter, setGenderFilter] = useState('')
  const [sDate, setSDate] = useState<any>('')
  const [eDate, setEDate] = useState<any>('')
  const [page, setPage] = useState(1)
  const [subscription, setSubscription] = useState('')
  const [ud, setUD] = useState([])

  return (
    <>
      <KTCard>
        {/* Search | Filter | Export Button Begins */}
        <UsersListHeader
          ud={ud}
          genderFilter={genderFilter}
          setGenderFilter={setGenderFilter}
          userSearch={userSearch}
          setUserSearch={setUserSearch}
          sDate={sDate}
          setSDate={setSDate}
          eDate={eDate}
          setEDate={setEDate}
          page={page}
          setPage={setPage}
          subscription={subscription}
          setSubscription={setSubscription}
        />
        {/* Search | Filter | Export Button Ends */}
        <UsersTable
          setUD={setUD}
          genderFilter={genderFilter}
          setGenderFilter={setGenderFilter}
          userSearch={userSearch}
          setUserSearch={setUserSearch}
          sDate={sDate}
          setSDate={setSDate}
          eDate={eDate}
          setEDate={setEDate}
          page={page}
          setPage={setPage}
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </KTCard>
    </>
  )
}

const UsersListWrapper = () => <UsersList />

export {UsersListWrapper}
