import React, {FC} from 'react'

type Props = {
  gender?: string
}

const UserGenderCell: FC<Props> = ({gender}) => {
  return <div>{gender?.toUpperCase()}</div>
}

export default UserGenderCell
