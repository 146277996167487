import {useFormik} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {
  useCreateNewAddMutation,
  useCreateNewRewardsMutation,
  useGetRewardByIdQuery,
  useUpdateRewardMutation,
} from '../../../../../../redux/api'

import FormData from 'form-data'
import {useParams} from 'react-router-dom'

let SUPPORTED_FORMATS = ['image/jpeg', 'image/png']

const profileDetailsSchema = Yup.object().shape({
  level: Yup.string().required('Level is required'),
  // redirectUrl:Yup.string().required("Url is required"),
  requiredPoints: Yup.number('Please enter a number')
    .required('Target points is required')
    .positive('Target points must be positive')
    .integer('Enter correct values')
    .typeError('Please enter a number'),
  reward: Yup.string().required('Reward is required'),

  image: Yup.mixed().nullable().required('Image is required'),
  // .test("FILE_SIZE","Uploaded file size is too big",
  // (value)=> !value || (value && value.size <= 1024*1024)
  // )
  // .test("FILE_FORMAT","Uploaded file has unsupported ",
  // (value)=> !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  // )
})

const EditRewards = () => {
  const {id} = useParams()
  const {data} = useGetRewardByIdQuery(id)
  const [updateReward, {isLoading}] = useUpdateRewardMutation()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [imgHeight, setImgHeight] = useState(0)
  const [imageWidth, setImgWidth] = useState(0)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?._id,
      level: data?.level,
      requiredPoints: data?.requiredPoints,
      reward: data?.reward,
      image: data?.image,
    },

    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      var form = new FormData()
      form.append('id', values.id)
      form.append('photo', values.image)
      form.append('level', values.level)
      form.append('requiredPoints', values.requiredPoints)
      form.append('reward', values.reward)

      for (const value of form.values()) {
      }

      await updateReward(form)
        .unwrap()
        .then((res) => {
          setShowModal(true)
          setMessage(res.success)
          formik.resetForm()
          values.image = null
        })
        .catch((err) => {
          console.table('failiure', err)
          setShowModal(true)
          setMessage(err.data.error)
          setError(true)
        })
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Edit Reward</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {formik.values.image && (
                <Preview
                  setImgHeight={setImgHeight}
                  setImageWidth={setImgWidth}
                  file={formik.values.image}
                />
              )}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Image Upload</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => {
                      formik.setFieldValue('image', event.target.files[0])
                    }}
                  />
                  {/* {formik.values.image ? (
                    <>
                      <div>File Size: {formik.values.image.size}</div>
                      <div>File Type: {formik.values.image.type}</div>
                    </>
                  ) : null} */}
                  {formik.touched.image && formik.errors.image && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.image}</div>
                    </div>
                  )}
                  <div className='mt-2 ml-1'>
                    {' '}
                    Note: Standard image resolution should be 889 x 485 and size should be less than
                    5 MB with jpeg format
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Level</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    data-control='select2'
                    data-placeholder='Latest'
                    data-hide-search='true'
                    className='form-select form-select-sm fs-6 form-select-solid'
                    placeholder='Level'
                    {...formik.getFieldProps('level')}
                  >
                    <option value='PROSPECT'>PROSPECT</option>
                    <option value='ROOKIE'>ROOKIE</option>
                    <option value='PRO'>PRO</option>
                    <option value='VETERAN'>VETERAN</option>
                    <option value='ALL STAR'>ALL STAR</option>
                    <option value='MVP'>MVP</option>
                    <option value='HALL OF FAMER'>HALL OF FAMER</option>
                    <option value='LEGEND'>LEGEND</option>
                    <option value='GOAT'>GOAT</option>
                  </select>
                  {formik.touched.level && formik.errors.level && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.level}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Target Points</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Target Points
                    '
                    {...formik.getFieldProps('requiredPoints')}
                  />
                  {formik.touched.requiredPoints && formik.errors.requiredPoints && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.requiredPoints}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Reward</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Reward'
                    {...formik.getFieldProps('reward')}
                  />
                  {formik.touched.reward && formik.errors.reward && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.reward}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <NavLink
           to='/apps/user-management/users'
           > */}
              <button type='submit' disabled={isLoading} className='btn btn-primary'>
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <button type='submit' className='btn btn-primary'>
                Submit
              </button> */}
              {/* </NavLink> */}
            </div>
          </form>
        </div>
        {showModal && (
          <MessageModal
            error={error}
            showModal={showModal}
            setShowModal={setShowModal}
            message={message}
            setError={setError}
          />
        )}
      </div>
    </>
  )
}

const MessageModal = ({showModal, setShowModal, message, error, setError}) => {
  return (
    <>
      {' '}
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'>{error ? 'Error' : 'Success'}</h5>
                  {/* <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick = {()=> setShowModal(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div> */}
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {/* <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button> */}
                  <button
                    onClick={() => {
                      setShowModal(false)
                      setError(false)
                    }}
                    type='button'
                    className='btn btn-primary'
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

const Preview = ({file, setImgHeight, setImageWidth}) => {
  const [preview, setPreview] = useState(null)
  const [imgUrl, setImgUrl] = useState('')
  const reader = new FileReader()

  if (typeof file === 'string') {
    // setPreview(file);
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
          {file ? (
            <img
              style={{justifySelf: 'center', height: '300px', wigth: '300px'}}
              src={file}
              alt='preview'
            />
          ) : (
            'loading..'
          )}
        </div>
      </>
    )
  }

  reader.readAsDataURL(file)
  reader.onload = () => {
    setPreview(reader.result)
    var img = new Image()
    img.src = reader.result
    img.onload = () => {
      setImgHeight(img.height)
      setImageWidth(img.width)
    }
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
        {preview ? (
          <img
            style={{justifySelf: 'center', height: '300px', wigth: '300px'}}
            src={preview}
            alt='preview'
          />
        ) : (
          'loading..'
        )}
      </div>
    </>
  )
}

export default EditRewards
