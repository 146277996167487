import {gql} from '@apollo/client'

export const DELETE_FEEDBACK = gql`
  mutation DeleteFeedback($deleteFeedbackId: String!) {
    deleteFeedback(id: $deleteFeedbackId) {
      message
      status
    }
  }
`
