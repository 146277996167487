/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = ({
  page,
  setPage,
  totalPages,
  loading,
  paginationDetails,
  setPageSize,
  pageSize,
}: any) => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  console.log('totalPages', totalPages)
  let pages = Array.from(Array(totalPages), (e, i) => i + 1)

  const pageSizes = [10, 25, 50, 100]
  const pageNumbers = Array.from({length: paginationDetails.totalPages}, (_, index) => index + 1)

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: loading ? true : !paginationDetails?.hasPrevPage,
                previous: loading ? true : paginationDetails?.hasPrevPage,
              })}
            >
              <a
                className={clsx('page-link', {})}
                onClick={() => setPage(page - 1)}
                style={{cursor: 'pointer'}}
              >
                {mappedLabel('Previous')}
              </a>
            </li>

            {pages.map((item) => (
              <li
                key={item}
                className={clsx('page-item', {
                  active: item === page,
                })}
              >
                <a
                  className={clsx('page-link', {})}
                  onClick={() => setPage(item)}
                  style={{cursor: 'pointer'}}
                >
                  {item}
                </a>
              </li>
            ))}

            <li
              className={clsx('page-item', {
                disabled: loading ? true : !paginationDetails?.hasNextPage,
                next: loading ? true : paginationDetails?.hasNextPage,
              })}
            >
              <a
                className={clsx('page-link', {})}
                onClick={() => setPage(page + 1)}
                style={{cursor: 'pointer'}}
              >
                {mappedLabel('Next')}
              </a>
            </li>

            <li>
              <select
                className='page-item'
                value={page}
                onChange={(e) => setPage(parseInt(e.target.value))}
              >
                {pageNumbers &&
                  pageNumbers.map((pageNumber: number) => (
                    <option key={pageNumber} value={pageNumber}>
                      Page {pageNumber}
                    </option>
                  ))}
              </select>
            </li>

            <li>
              <select
                className='page-item mx-2'
                value={pageSize}
                onChange={(e) => setPageSize(parseInt(e.target.value))}
              >
                {pageSizes &&
                  pageSizes.map((pageNumber: number) => (
                    <option key={pageNumber} value={pageNumber}>
                      limit {pageNumber}
                    </option>
                  ))}
              </select>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
