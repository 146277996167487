import {gql} from '@apollo/client'

export const DELETE_MANY_PRIORITY = gql`
  mutation DeleteManyPrioritiesById($ids: [String!]!) {
    deleteManyPrioritiesById(ids: $ids) {
      status
      message
    }
  }
`
