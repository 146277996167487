// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import UserPhoneNumberCell from './UserPhoneNumberCell'
import UserGenderCell from './UserGenderCell'
import UserLocationCell from './UserLocationCell'
import {UserStatusCell} from './UserStatusCell'
import {UserActionsCell} from './UserActionsCell'
import moment from 'moment'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: '_id',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].subscription} />,
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Location' className='min-w-125px' />
  //   ),
  //   id: 'location',
  //   Cell: ({...props}) => <UserLocationCell phone={props.data[props.row.index].phone} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Feedback' className='min-w-125px' />
    ),
    accessor: 'feedback',
    Cell: ({...props}) => {
      var description = props.data[props.row.index].description
      return <div>{description}</div>
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => {
      var created_date = new Date(props.data[props.row.index].createdAt)

      return <div>{moment(created_date).format('LL')}</div>
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
