import {FC, useEffect, useMemo} from 'react'
import {ID} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useDispatch, useSelector} from 'react-redux'
import {setSelectedFeedbacks} from '../../../../../../redux/slices/general_slice'

type Props = {
  id: ID
}

const UserSelectionCell: FC<Props> = ({id}) => {
  const dispatch = useDispatch()
  const selectedFeedbacks = useSelector((state: any) => state.general.selectedFeedbacks)

  console.log(selectedFeedbacks, 'selected')
  const handleSelect = () => {
    if (selectedFeedbacks.includes(id)) {
      const filtered = selectedFeedbacks.filter((item: any) => item !== id)
      dispatch(setSelectedFeedbacks(filtered))
    } else {
      const filtered = [...selectedFeedbacks, id]
      //@ts-ignore
      dispatch(setSelectedFeedbacks(filtered))
    }
  }

  useEffect(() => {
    return () => {
      const defaultList: any[] = []
      dispatch(setSelectedFeedbacks(defaultList))
    }
  }, [])
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={selectedFeedbacks.includes(id)}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={selectedFeedbacks.includes(id)}
        onChange={handleSelect}
      />
    </div>
  )
}

export {UserSelectionCell}
