import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import SubAdminDetails from '../SubAdmins/SubAdminDetails/SubAdminDetails'
import {UsersListWrapper} from './users-list/UsersList'
import UsersDetailsPage from './UsersDetailsPage'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Feed Management',
    path: '/apps/all-feedbacks/feedbacks',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersManagement = () => {
  return (
    <Routes>
      {/* <Route element={<Outlet />}> */}
      <Route
        path='feedbacks'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Feedbacks list</PageTitle>
            <UsersListWrapper />
          </>
        }
      />
      <Route path='feedbacks/details-page' element={<UsersDetailsPage />} />

      {/* </Route> */}
      <Route element={<Navigate to='/apps/all-feedbacks/feedbacks' />} />
    </Routes>
  )
}

export default UsersManagement
