import {configureStore, combineReducers} from '@reduxjs/toolkit'
import {api} from './api'

import authReducer from './slices/auth_slice'
import generalSlice from './slices/general_slice'

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  general: generalSlice,
})

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
