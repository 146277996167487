// @ts-nocheck
import {addDays} from 'date-fns'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'

import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {DateRange} from 'react-date-range'
const UsersListFilter = ({
  page,
  setPage,
  sDate,
  eDate,
  setSDate,
  setEDate,
  genderFilter,
  setGenderFilter,
  subscription,
  setSubscription,
}) => {
  const [startDate, setStartDate] = useState(sDate)

  const [endDate, setEndDate] = useState(eDate)

  const [gender, setGender] = useState(genderFilter)

  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    // updateState({filter: undefined, ...initialQueryState})
    setGenderFilter('')
    setSDate('')
    setEDate('')
    setPage(1)
    setSubscription('')
  }

  const filterData = () => {
    // updateState({
    //   filter: {role, last_login: lastLogin},
    //   ...initialQueryState,
    // }),
    // setSubscription('')
    // setGenderFilter('')
    // setSDate('')
    // setEDate('')
    setPage(1)
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>

      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-2'>
          <div className='fs-6 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-1' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

          <div>
            <label className='form-label fs-6 fw-bold'>Start date:</label>
            <input
              className='form-select form-select-solid fw-bolder'
              type='date'
              value={sDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => setSDate(e.target.value)}
            />
          </div>

          <div className='mb-2'>
            <label className='form-label fs-6 fw-bold'>End date:</label>
            <input
              className='form-select form-select-solid fw-bolder'
              type='date'
              value={eDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => setEDate(e.target.value)}
            />
          </div>

          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
