import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import useAuth from '../../../hooks/useAuth'
import OtpForm from './OtpForm'
import {KTSVG} from '../../../../_metronic/helpers'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [countdown, setCountdown] = useState(60) // Initial countdown value in seconds
  const onChange = (value: any) => setOtp(value)
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [passwordMatched, setPasswordMatched] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [passwordChangeError, setPasswordChangeError] = useState(false)

  const {verifyOtp, forgotPassword} = useAuth()

  const [countdownReached, setCountdownReached] = useState(false)

  const {getOtp} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await getOtp(values.email)
        setOtpSent(true)
      } catch (error) {
        setHasErrors(true)
      } finally {
        setLoading(false)
      }
    },
  })

  const handleRequestOTP = async (e: any) => {
    e.preventDefault()
    setCountdown(60)
    setCountdownReached(false)

    getOtp(formik.values.email)
  }

  const handleOtpVerification = async (e: any) => {
    e.preventDefault()
    const response: any = await verifyOtp(formik.values.email, otp)
    if (response?.data?.verifyEmailOTP?.status === '200') {
      setOtpSuccess(true)
    } else {
      setOtpSuccess(false)
      setOtpError(true)
    }
  }

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleNewPasswordChange = (event: any) => {
    setNewPassword(event.target.value)
    setPasswordMatched(event.target.value === confirmPassword)
  }

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
    setPasswordMatched(event.target.value === newPassword)
  }

  const handleResetPassword = async (e: any) => {
    e.preventDefault()
    if (!passwordMatched || !newPassword || !confirmPassword) {
      return
    }

    try {
      setLoading(true)
      const response: any = await forgotPassword(formik.values.email, newPassword)

      if (response?.data?.forgotResetAdminPassword?.status === '204') {
        setShowModal(true)
      } else {
        setPasswordChangeError(true)
      }
    } catch (error) {}
  }
  return (
    <>
      {!otpError && otpSuccess && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={handleResetPassword}
        >
          <div className='text-center mb-10'>
            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>Enter your new password.</div>
            {/* end::Link */}
          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <div>
              <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                value={newPassword}
                onChange={handleNewPasswordChange}
                disabled={passwordChangeError}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': !passwordMatched},
                  {
                    'is-valid': passwordMatched,
                  }
                )}
              />
            </div>

            <div>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                disabled={passwordChangeError}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': !passwordMatched},
                  {
                    'is-valid': passwordMatched,
                  }
                )}
              />
            </div>
          </div>
          {passwordChangeError && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like an error happened please try again later
              </div>
            </div>
          )}

          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={passwordMatched}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
      {otpSent && !otpSuccess && (
        <div className='w-100'>
          <h3 className='title text-center my-4' style={{textAlign: 'center'}}>
            OTP Verification
          </h3>
          <OtpForm
            value={otp}
            valueLength={6}
            otpError={otpError}
            setOtpError={setOtpError}
            onChange={onChange}
            handleOtpVerification={handleOtpVerification}
            countdown={countdown}
            setCountdown={setCountdown}
            countdownReached={countdownReached}
            setCountdownReached={setCountdownReached}
            handleRequestOTP={handleRequestOTP}
          />
        </div>
      )}
      {!otpSent && !otpSuccess && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>
              Enter your email to reset your password.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
      {showModal && <MessageModal showModal={showModal} setShowModal={setShowModal} />}
    </>
  )
}

const MessageModal = ({showModal, setShowModal}: any) => {
  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'> {'Delete'}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>Password have been changed successfully please login.</p>
                </div>
                <div className='modal-footer'>
                  <>
                    <Link to='/auth/login'>
                      <button
                        type='button'
                        className='btn btn-light'
                        onClick={() => setShowModal(false)}
                        data-bs-dismiss='modal'
                      >
                        Login
                      </button>
                    </Link>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}
