/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import useUsers from '../../../../../hooks/useUsers'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        {/* <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' /> */}
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => setShowModal(true)}
          >
            Delete
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}

      {showModal && (
        <MessageModal
          // data={data}
          id={id}
          showModal={showModal}
          setShowModal={setShowModal}
          message={'error'}
          // error={error}
        />
      )}
    </>
  )
}

const MessageModal = ({
  id,

  showModal,
  setShowModal,
  error,
}: any) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('Are you sure you want to delete this Feedback?')
  const [isDone, setIsDone] = useState(false)
  const {deleteUserFeedBacks} = useUsers()
  const handleDelete = async (id: string) => {
    setLoading(true)
    try {
      const response = await deleteUserFeedBacks(id)

      setMessage('Feedback is Deleted Successfully')
      setIsDone(true)
    } catch (error) {
      setMessage('Something went wrong please try again later.')
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-550px'>
            <div className='modal-content'>
              <div className='modal-body scroll-y '>
                <div className='modal-header'>
                  <h5 className='modal-title fs-2'> {'Delete'}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setShowModal(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-3'>
                  <p>{message}</p>
                </div>
                <div className='modal-footer'>
                  {isDone ? (
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={() => {
                        setShowModal(false)
                        window.location.reload()
                      }}
                      data-bs-dismiss='modal'
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='btn btn-light'
                        onClick={() => setShowModal(false)}
                        data-bs-dismiss='modal'
                      >
                        No
                      </button>
                      <button
                        onClick={async () => {
                          await handleDelete(id)
                        }}
                        type='button'
                        disabled={loading}
                        className='btn btn-primary'
                      >
                        {loading ? 'Please wait...' : 'Yes'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-backdrop fade show'></div>
      </>
    </>
  )
}

export {UserActionsCell}
