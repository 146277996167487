// @ts-nocheck
import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row, usePagination} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User} from '../core/_models'
import {KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {CustomRow} from './columns/CustomRow'
import {gql, useLazyQuery, useQuery} from '@apollo/client'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {UsersListPagination} from '../components/pagination/UsersListPagination'

type SearchProps = {
  userSearch: String
  setUserSearch: Function
}
export let AllUsersData
let searchUsers = []

const clean = (text) => text.replace(/[^a-zA-Z0-9 ]/g, '')

const UsersTable = ({
  sDate,
  eDate,
  genderFilter,
  setGenderFilter,
  userSearch,
  setUserSearch,
  page,
  subscription,
  setPage,
  setUD,
}: SearchProps) => {
  let gender = genderFilter
  const [loadingTrue, setLoadingTrue] = useState<boolean>(false)
  const startDate = sDate == '' ? '' : new Date(sDate).toLocaleString()
  const endDate = eDate == '' ? '' : new Date(eDate).toLocaleString()
  const [paginationDetails, setPaginationDetails] = useState({})
  const [usersData, setUsersData] = useState([])
  const [loading, setLoading] = useState(false)

  const GET_ALL_USERS: any = gql`
    query GetAllUsers(
      $page: Int
      $perPage: Int
      $endDate: String
      $startDate: String
      $gender: String
      $searchString: String
      $subscription: String
    ) {
      getAllUsers(
        page: $page
        perPage: $perPage
        endDate: $endDate
        startDate: $startDate
        gender: $gender
        searchString: $searchString
        subscription: $subscription
      ) {
        docs {
          _id
          first_name
          email
          gender
          last_name
          phone_number
          profile_picture
          date_of_birth
          isBlocked
          subscriptionType
        }
        page
        totalPages
        totalDocs
        limit
        hasPrevPage
        hasNextPage
        nextPage
        pagingCounter
        prevPage
      }
    }
  `

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS)

  const [pageNumbers, setPageNumbers] = useState()
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (userSearch) {
        setPage(1)
      }
      const request = {
        variables: {
          page: userSearch ? 1 : page,
          perPage: perPage,
          gender,
          startDate,
          endDate,
          subscription,
          searchString: userSearch,
        },
      }
      const {data} = await getAllUsers(request)

      setUsersData(data?.getAllUsers?.docs)
      setUD(data?.getAllUsers?.docs)
      setPaginationDetails(data?.getAllUsers)
      setLoading(false)
      const p = Array.from({length: data?.getAllUsers.totalPages}, (_, index) => index + 1)
      setPageNumbers(p)
    }

    init()
  }, [page, gender, startDate, endDate, subscription, userSearch, perPage])

  const data = useMemo(() => usersData, [usersData])
  const columns = useMemo(() => usersColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  // const {selected} = useListView()

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!loading && paginationDetails && (
        <UsersListPagination
          page={page}
          setPage={setPage}
          totalPages={
            page + 5 < paginationDetails.totalPages ? page + 5 : paginationDetails.totalPages
          }
          loading={loading}
          paginationDetails={paginationDetails}
          pageNumbers={pageNumbers}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      )}

      {loading && <UsersListLoading />}
      {/* {isFetching && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export {UsersTable}
