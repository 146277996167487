import {useLazyQuery} from '@apollo/client'
import {useEffect, useState} from 'react'
import {FETCH_PAGEABLE_ALBUMS} from '../queries/album.query'
import {Album} from '../types/album.type'
import {GET_FAQS} from '../queries/faq.query'
import {Faq} from '../types/faq.types'

type MainData = {
  status?: string
  message?: string
  data?: Faq
}

type Response = {
  getFaq: MainData
  loading: boolean
}

type FetchResponse = {
  faq: Faq | any
  error: boolean
  loading: boolean
  refetchFaq: () => void
}

const useFetchFaq = (): FetchResponse => {
  const [faq, setFaq] = useState<Faq>()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [getFaq] = useLazyQuery<Response>(GET_FAQS)

  const fetchFaq = async () => {
    try {
      const res = await getFaq()
      if (!res.loading && !res.error) {
        const data = res.data?.getFaq.data
        if (res.data !== undefined) {
          setLoading(false)
          setFaq(data)
        }
      }
      if (res.error) {
        setError(true)
      }
    } catch (error) {
      setLoading(false)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const refetchFaq = async () => {
    await fetchFaq()
  }

  useEffect(() => {
    fetchFaq()
  }, [])
  return {faq, error, loading, refetchFaq}
}

export default useFetchFaq
