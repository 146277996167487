import {useLazyQuery, useMutation} from '@apollo/client'
import {DELETE_MANY_FEEDBACKS, FETCH_PAGINATED_FEEDBACKS} from '../queries/feedback.query'

const useFeedback = () => {
  const [getUserFeedbacks] = useLazyQuery(FETCH_PAGINATED_FEEDBACKS)

  const [deleteFeedbacks] = useMutation(DELETE_MANY_FEEDBACKS)
  const getFeedbacks = async (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    searchString: string
  ) => {
    try {
      const request = {
        variables: {
          page,
          pageSize,
          startDate,
          endDate,
          searchString,
        },
      }

      const {data, error} = await getUserFeedbacks(request)

      console.log(data, 'response')
      return {
        data,
        error,
      }
    } catch (error: any) {
      console.log(error, 'error')
      throw new Error(error)
    }
  }

  const deleteManyFeedbacks = async (ids: string[]) => {
    try {
      const request = {
        variables: {
          ids,
        },
      }

      const {data, errors} = await deleteFeedbacks(request)
      return {
        data,
        errors,
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    getFeedbacks,
    deleteManyFeedbacks,
  }
}

export default useFeedback
