import {gql} from '@apollo/client'

export const CHECK_IS_PRIORITY_TAKEN = gql`
  query IsPriorityTaken(
    $priority: Int
    $priorityId: String
    $contentType: String
    $isEdit: Boolean
  ) {
    isPriorityTaken(
      priority: $priority
      priorityId: $priorityId
      contentType: $contentType
      isEdit: $isEdit
    ) {
      isTaken
      status
    }
  }
`

export const ADD_PRIORITY = gql`
  mutation CreatePriority(
    $albumId: String!
    $type: String!
    $location: String!
    $priority: Int!
    $contentType: String!
  ) {
    createPriority(
      albumId: $albumId
      type: $type
      location: $location
      priority: $priority
      contentType: $contentType
    ) {
      data {
        _id
        title
        type
        location
        priority
        contentType
        createdAt
      }
      status
    }
  }
`

export const FETCH_PAGINATED_PRIORITY = gql`
  query GetPriorityByContentTypes(
    $page: Int!
    $pageSize: Int!
    $contentType: String!
    $startDate: String
    $endDate: String
    $searchString: String
  ) {
    getPriorityByContentTypes(
      page: $page
      pageSize: $pageSize
      contentType: $contentType
      startDate: $startDate
      endDate: $endDate
      searchString: $searchString
    ) {
      status
      data {
        _id
        title
        album {
          nature
          title
          cover_image_url
          _id
        }
        type
        location
        priority
        contentType
        createdAt
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`

export const UPDATE_PRIORITY = gql`
  mutation UpdatePriority(
    $updatePriorityId: String!
    $albumId: String!
    $type: String!
    $location: String!
    $priority: Int!
    $contentType: String!
  ) {
    updatePriority(
      id: $updatePriorityId
      albumId: $albumId
      type: $type
      location: $location
      priority: $priority
      contentType: $contentType
    ) {
      status
      message
      data {
        _id
        type
        location
        priority
        contentType
        createdAt
      }
    }
  }
`

export const DELETE_PRIORITY = gql`
  mutation DeletePriority($deletePriorityId: String!) {
    deletePriority(id: $deletePriorityId) {
      data {
        _id
        type
        location
        priority
        contentType
        createdAt
      }
      status
    }
  }
`

export const GET_PRIORITY_BY_ID = gql`
  query GetPriorityByIdR($getPriorityByIdRId: String!) {
    getPriorityByIdR(id: $getPriorityByIdRId) {
      status
      message
      data {
        _id
        type
        location
        priority
        album {
          _id
          title
          nature
          cover_image_url
        }
        title
        contentType
        createdAt
      }
    }
  }
`

export const GET_ALBUM_IDS = gql`
  query GetAlbumIdsInPriority($contentType: String) {
    getAlbumIdsInPriority(contentType: $contentType) {
      status
      data
    }
  }
`

export const GET_PRIORITIES_ANALYTICS = gql`
  query PrioritiesAnalytics {
    prioritiesAnalytics {
      status
      featuredSeriesCount
      featuredVideosCount
      popularSeriesCount
      recommendedPlaylistsCount
    }
  }
`
