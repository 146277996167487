// @ts-nocheck
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../_metronic/helpers'

const DashboardFilter = ({sDate, eDate, setSDate, setEDate}) => {
  const [startDate, setStartDate] = useState(sDate)

  const [disabled, setDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [endDate, setEndDate] = useState(eDate)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    //updateState({filter: undefined, ...initialQueryState})
    setSDate(startDate)
    setEDate(endDate)
  }

  const filterData = () => {
    setSDate('')
    setEDate('')
    setStartDate('')
    setEndDate('')
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Overall Analytics Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px position-absolute shadow'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

          {/* end::Input group */}

          {/* begin::Input group */}

          {/* end::Input group */}

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Start date:</label>
            <input
              className='form-select form-select-solid fw-bolder'
              type='date'
              value={sDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => {
                const currentDate = new Date()
                const startDate = new Date(e.target.value)
                const isDateEqual =
                  currentDate.getFullYear() === startDate.getFullYear() &&
                  currentDate.getMonth() === startDate.getMonth() &&
                  currentDate.getDate() === startDate.getDate()
                console.log('is true', isDateEqual)
                if (isDateEqual) {
                  setDisabled(true)
                } else {
                  setDisabled(false)
                }
                setSDate(e.target.value)
              }}
            />
          </div>

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>End date:</label>
            <input
              className='form-select form-select-solid fw-bolder'
              type='date'
              value={eDate}
              disabled={disabled}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => setEDate(e.target.value)}
            />
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {DashboardFilter}
