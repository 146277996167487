import S3 from 'aws-sdk/clients/s3'

// import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require('buffer').Buffer

const signatureVersion = 'v4'
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
const secretAccessKey = process.env.REACT_APP_AWS_KEY_SECRET

const MUNROE_BUCKET_NAME = 'munroe-app-data'
const ACCELERATED_ENDPOINT = process.env.REACT_APP_ACCELERATED_END_POINT
interface UploadFileOptions {
  file: any
  title: string
  type: string
}

const uploadDirectOnS3 = async ({file, title, type}: UploadFileOptions): Promise<any> => {
  const s3bucket = new S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    signatureVersion: signatureVersion,
    //endpoint: ACCELERATED_ENDPOINT,
  })

  const keyName = `${type}/${title}/${file.name}`
  const params = {
    Bucket: MUNROE_BUCKET_NAME,
    Key: keyName,
    Body: file,
  }

  try {
    const {Location} = await s3bucket.upload(params).promise()
    return Location
  } catch (err) {
    throw err
  }
}

const uploadImageOnS3 = async ({file, title, type}: UploadFileOptions): Promise<any> => {
  const s3bucket = new S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    //signatureVersion: signatureVersion,
    //endpoint: ACCELERATED_ENDPOINT,
  })

  const keyName = `${type}/${title.replace(/-/g, ' ')}/${file.name.replace(/-/g, ' ')}`
  const params = {
    Bucket: MUNROE_BUCKET_NAME,
    Key: keyName,
  }

  try {
    // Step 1: Create a multipart upload
    const {UploadId} = await s3bucket.createMultipartUpload(params).promise()
    const parts = []

    // Step 2: Upload parts of the file
    const fileSize = file.size
    const partSize = 10 * 1024 * 1024 // 10MB per part (adjust as needed)
    let start = 0
    let partNumber = 1
    let uploadedBytes = 0
    const totalParts = Math.ceil(fileSize / partSize) // Calculate the total nu

    while (start < fileSize) {
      const end = Math.min(start + partSize, fileSize)

      const partParams = {
        ...params,
        PartNumber: partNumber,
        UploadId: UploadId!,
        Body: file.slice(start, end),
      }

      const {ETag} = await s3bucket
        .uploadPart(partParams)
        .on('httpUploadProgress', (progress) => {
          const uploaded = progress.loaded
          uploadedBytes += uploaded
          const partProgress = Math.round((uploaded / partSize) * 100) // Progress for each part
          const overallProgress = Math.round((partNumber / totalParts) * 100) // Overall progress
        })
        .promise()
      parts.push({PartNumber: partNumber, ETag: ETag})

      start = end
      partNumber++
    }

    // Step 3: Complete the multipart upload
    const completeParams = {
      ...params,
      UploadId: UploadId!,
      MultipartUpload: {Parts: parts},
    }

    const {Location} = await s3bucket.completeMultipartUpload(completeParams).promise()
    return Location
  } catch (err) {
    throw err
  }
}

export {uploadImageOnS3, uploadDirectOnS3}

export default uploadImageOnS3
